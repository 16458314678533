import { ReactNode } from "react";

enum TopLevelJobMatchType {
    availableJobs = 'availableJobs',
    advice = 'advice',
}

export const textForTopLevelJobMatchType = (type: TopLevelJobMatchType): ReactNode => {
    switch (type) {
        case TopLevelJobMatchType.availableJobs:
            return <>Provide me with a list of <b>jobs that are currently available</b></>
        case TopLevelJobMatchType.advice:
            return <>Provide me with <b>advice</b> on the types of jobs I should be looking for based on my skills and experience</>
    }
}

export default TopLevelJobMatchType;