
import BackendPrompt, { BackendPromptType } from '../../profile/models/BackendPrompt';
import JobsService from '../../services/jobsService';
import PromptsService from '../../services/promptsService';
import CoverLetterType from '../../shared/enums/CoverLetterType';
import InterviewQuestionsType from '../../shared/enums/InterviewQuestionsType';
import JobMatchType from '../../shared/enums/JobMatchType';
import ResumeFixType from '../../shared/enums/ResumeType';
import TopLevelJobMatchType from '../../shared/enums/TopLevelJobMatchType';
import UpskillingType from '../../shared/enums/UpskillingType';
import Job from '../../shared/models/Job';
import { setAllPrompts } from '../reducers/promptReducer';
import { setSavedJobBuckets, setSavedJobs } from '../reducers/savedJobsReducer';
import { AppDispatch, RootState } from '../store';


export interface AllPrompts {
    [BackendPromptType.job]: {
        [JobMatchType.resumeAndPreferences]: BackendPrompt;
        [JobMatchType.specificRole]: BackendPrompt;
        [TopLevelJobMatchType.advice]: BackendPrompt;
    };
    [BackendPromptType.resume]: {
        [ResumeFixType.improvements]: BackendPrompt;
        [ResumeFixType.bestPractices]: BackendPrompt;
        [ResumeFixType.savedJobs]: BackendPrompt;
        [ResumeFixType.specificRole]: BackendPrompt;
    };
    [BackendPromptType.coverLetter]: {
        [CoverLetterType.general]: BackendPrompt;
        [CoverLetterType.savedJobs]: BackendPrompt;
        [CoverLetterType.specificRole]: BackendPrompt;
    };
    [BackendPromptType.upskill]: {
        [UpskillingType.resumeAndPreferences]: BackendPrompt;
        [UpskillingType.savedJobs]: BackendPrompt;
        [UpskillingType.specificRole]: BackendPrompt;
    };
    [BackendPromptType.interviewPrep]: {
        [InterviewQuestionsType.resume]: BackendPrompt;
        [InterviewQuestionsType.job]: BackendPrompt;
        [InterviewQuestionsType.specified]: BackendPrompt;
    };
}

export const FetchAllPrompts = () => {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const allPrompts = await PromptsService.fetchAllPrompts();
        if (allPrompts) {
            const allPromptStruct: AllPrompts = {
                [BackendPromptType.job]: {
                    [JobMatchType.resumeAndPreferences]: allPrompts.find(prompt => prompt.type === BackendPromptType.job && prompt.ref === JobMatchType.resumeAndPreferences) ?? { id: 0, prompt: '', ref: '', type: BackendPromptType.job },
                    [JobMatchType.specificRole]: allPrompts.find(prompt => prompt.type === BackendPromptType.job && prompt.ref === JobMatchType.specificRole) ?? { id: 0, prompt: '', ref: '', type: BackendPromptType.job },
                    [TopLevelJobMatchType.advice]: allPrompts.find(prompt => prompt.type === BackendPromptType.job && prompt.ref === TopLevelJobMatchType.advice) ?? { id: 0, prompt: '', ref: '', type: BackendPromptType.job },
                },
                [BackendPromptType.resume]: {
                    [ResumeFixType.improvements]: allPrompts.find(prompt => prompt.type === BackendPromptType.resume && prompt.ref === ResumeFixType.improvements) ?? { id: 0, prompt: '', ref: '', type: BackendPromptType.resume },
                    [ResumeFixType.bestPractices]: allPrompts.find(prompt => prompt.type === BackendPromptType.resume && prompt.ref === ResumeFixType.bestPractices) ?? { id: 0, prompt: '', ref: '', type: BackendPromptType.resume },
                    [ResumeFixType.savedJobs]: allPrompts.find(prompt => prompt.type === BackendPromptType.resume && prompt.ref === ResumeFixType.savedJobs) ?? { id: 0, prompt: '', ref: '', type: BackendPromptType.resume },
                    [ResumeFixType.specificRole]: allPrompts.find(prompt => prompt.type === BackendPromptType.resume && prompt.ref === ResumeFixType.specificRole) ?? { id: 0, prompt: '', ref: '', type: BackendPromptType.resume },
                },
                [BackendPromptType.coverLetter]: {
                    [CoverLetterType.general]: allPrompts.find(prompt => prompt.type === BackendPromptType.coverLetter && prompt.ref === CoverLetterType.general) ?? { id: 0, prompt: '', ref: '', type: BackendPromptType.coverLetter },
                    [CoverLetterType.savedJobs]: allPrompts.find(prompt => prompt.type === BackendPromptType.coverLetter && prompt.ref === CoverLetterType.savedJobs) ?? { id: 0, prompt: '', ref: '', type: BackendPromptType.coverLetter },
                    [CoverLetterType.specificRole]: allPrompts.find(prompt => prompt.type === BackendPromptType.coverLetter && prompt.ref === CoverLetterType.specificRole) ?? { id: 0, prompt: '', ref: '', type: BackendPromptType.coverLetter },
                },
                [BackendPromptType.upskill]: {
                    [UpskillingType.resumeAndPreferences]: allPrompts.find(prompt => prompt.type === BackendPromptType.upskill && prompt.ref === UpskillingType.resumeAndPreferences) ?? { id: 0, prompt: '', ref: '', type: BackendPromptType.upskill },
                    [UpskillingType.savedJobs]: allPrompts.find(prompt => prompt.type === BackendPromptType.upskill && prompt.ref === UpskillingType.savedJobs) ?? { id: 0, prompt: '', ref: '', type: BackendPromptType.upskill },
                    [UpskillingType.specificRole]: allPrompts.find(prompt => prompt.type === BackendPromptType.upskill && prompt.ref === UpskillingType.specificRole) ?? { id: 0, prompt: '', ref: '', type: BackendPromptType.upskill },
                },
                [BackendPromptType.interviewPrep]: {
                    [InterviewQuestionsType.resume]: allPrompts.find(prompt => prompt.type === BackendPromptType.interviewPrep && prompt.ref === InterviewQuestionsType.resume) ?? { id: 0, prompt: '', ref: '', type: BackendPromptType.interviewPrep },
                    [InterviewQuestionsType.job]: allPrompts.find(prompt => prompt.type === BackendPromptType.interviewPrep && prompt.ref === InterviewQuestionsType.job) ?? { id: 0, prompt: '', ref: '', type: BackendPromptType.interviewPrep },
                    [InterviewQuestionsType.specified]: allPrompts.find(prompt => prompt.type === BackendPromptType.interviewPrep && prompt.ref === InterviewQuestionsType.specified) ?? { id: 0, prompt: '', ref: '', type: BackendPromptType.interviewPrep },
                }
            }
            //  Print all the types of the prompts

            dispatch(setAllPrompts(allPromptStruct));
        }
    }
};

