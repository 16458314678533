import { initialMessageForJobMatchType, jobMatchStartMessage, titleForJobMatchType } from "../../chat/utils/JobMatchHelper";
import { topLevelJobMatchStartMessage } from "../../chat/utils/TopLevelJobMatchHelper";
import JobMatchType from "../../shared/enums/JobMatchType";
import PromptType from "../../shared/enums/PromptType";
import TopLevelJobMatchType from "../../shared/enums/TopLevelJobMatchType";
import { newJobMatchChat, newTopLevelJobMatchChat } from "../../shared/models/Chat";
import Job from "../../shared/models/Job";
import Message, { MessageType } from "../../shared/models/Message";
import { focusText, setActiveChat } from "../reducers/appReducer";
import { AppDispatch, RootState } from "../store";
import { sendMessageAction } from "./ChatActions";
import { StartJobMatchAction, StartJobMatchFlowAction } from "./JobMatchActions";

export const topLevelJobPromptMessage = '**Lets get started with your job search.**';

export const StartTopLevelJobMatchFlowAction = () => {
    return async (dispatch: AppDispatch) => {
        try {
            const message: Message = {
                content: topLevelJobPromptMessage,
                dt: (new Date()).toISOString(),
                type: MessageType.received,
            }
            const newChat = newTopLevelJobMatchChat([message], `Job Help`);
            dispatch(setActiveChat(newChat));
        } catch (error) {
            console.error('Error starting top level job match chat:', error);
        }
    };
}

export const SetTopLevelJobMatchTypeAction = (type: TopLevelJobMatchType) => {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        try {
            const chat = getState().appState.activeChat;
            if (!chat) return;
            if (type == TopLevelJobMatchType.advice) {
                dispatch(StartTopLevelJobMatchAction(type));
            } else {
                dispatch(StartJobMatchFlowAction());
            }
        } catch (error) {
            console.error('Error setting job match type:', error);
        }
    };
}

export const StartTopLevelJobMatchAction = (type: TopLevelJobMatchType, job?: Job, role?: string) => {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        try {
            const state = getState();
            dispatch(sendMessageAction(topLevelJobMatchStartMessage(type, state, job, role), true, false, { promptType: PromptType.jobAdvice }));
        } catch (error) {
            console.error('Error starting top level job match chat:', error);
        }
    };
}
