
import { ExpandOutlined } from '@mui/icons-material';
import { resumeStartMessage } from '../../chat/utils/ResumeHelper';
import SavedResume, { SavedResumeType } from '../../mainTabs/savedResumes/model/SavedResume';
import ResumeService from '../../services/resumeService';
import ResumeFixType from '../../shared/enums/ResumeType';
import { newResumeChat } from '../../shared/models/Chat';
import Job from '../../shared/models/Job';
import Message, { MessageType } from '../../shared/models/Message';
import { setActiveChat, toggleFollowChat } from '../reducers/appReducer';
import { setSavedCoverLetterBuckets, setSavedCoverLetters, setSavedResumeBuckets, setSavedResumes } from '../reducers/savedResumeReducer';
import { AppDispatch, RootState } from '../store';
import { sendMessageAction } from './ChatActions';
import PromptType from '../../shared/enums/PromptType';

export const resumePromptMessage = 'How can I improve your resume?';

export const StartResumeFlowAction = () => {
    return async (dispatch: AppDispatch) => {
        try {
            const message: Message = {
                content: resumePromptMessage,
                dt: (new Date()).toISOString(),
                type: MessageType.received,
            }
            const newChat = newResumeChat([message], `Resume Improvement`);
            dispatch(setActiveChat(newChat));
        } catch (error) {
            console.error('Error starting resume chat:', error);
        }
    };
}

export const StartResumeOptimizationAction = (type: ResumeFixType, job?: Job, role?: string) => {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        try {
            const state = getState();
            // Create a new chat with the appropriate prompt
            dispatch(sendMessageAction(resumeStartMessage(type, state, job, role), true, false, { promptType: PromptType.resume }));
            dispatch(toggleFollowChat());
        } catch (error) {
            console.error('Error starting resume chat:', error);
        }
    };
}

export const FetchSavedResumesAction = () => {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const resumesAndLetters = await ResumeService.fetchSavedResumes();
        const resumes = resumesAndLetters?.filter((resume) => resume.type === SavedResumeType.resume || !resume.type);
        if (resumes) {
            const sortedResumes = resumes.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());
            const today = new Date();
            let bucketKeys: string[] = ['Today', 'This Week'];
            const sortedResumesHistory = sortedResumes.reduce((buckets: { [key: string]: SavedResume[] }, resume: SavedResume) => {
                const createdDate = new Date(resume.updatedAt ?? resume.createdAt);
                const year = createdDate.getFullYear();
                const month = createdDate.toLocaleString('default', { month: 'long' });

                let bucketKey = '';
                if (createdDate.toDateString() === today.toDateString()) {
                    bucketKey = 'Today';
                } else if (createdDate >= new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay())) {
                    bucketKey = 'This Week';
                } else {
                    bucketKey = `${month} ${year}`;
                }

                if (!buckets[bucketKey]) {
                    if (!bucketKeys.includes(bucketKey)) {
                        bucketKeys.push(bucketKey);
                    }
                    buckets[bucketKey] = [];
                }
                buckets[bucketKey].push(resume);

                return buckets;
            }, {});

            for (const bucketKey in sortedResumesHistory) {
                sortedResumesHistory[bucketKey].sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());
            }
            dispatch(setSavedResumeBuckets(bucketKeys));
            dispatch(setSavedResumes(sortedResumesHistory));
        }
        const letters = resumesAndLetters?.filter((resume) => resume.type === SavedResumeType.coverLetter);
        if (letters) {
            const sortedLetters = letters.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());
            const today = new Date();
            let bucketKeys: string[] = ['Today', 'This Week'];
            const sortedLettersHistory = sortedLetters.reduce((buckets: { [key: string]: SavedResume[] }, coverLetter: SavedResume) => {
                const createdDate = new Date(coverLetter.updatedAt ?? coverLetter.createdAt);
                const year = createdDate.getFullYear();
                const month = createdDate.toLocaleString('default', { month: 'long' });

                let bucketKey = '';
                if (createdDate.toDateString() === today.toDateString()) {
                    bucketKey = 'Today';
                } else if (createdDate >= new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay())) {
                    bucketKey = 'This Week';
                } else {
                    bucketKey = `${month} ${year}`;
                }

                if (!buckets[bucketKey]) {
                    if (!bucketKeys.includes(bucketKey)) {
                        bucketKeys.push(bucketKey);
                    }
                    buckets[bucketKey] = [];
                }
                buckets[bucketKey].push(coverLetter);

                return buckets;
            }, {});

            for (const bucketKey in sortedLettersHistory) {
                sortedLettersHistory[bucketKey].sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());
            }
            dispatch(setSavedCoverLetterBuckets(bucketKeys));
            dispatch(setSavedCoverLetters(sortedLettersHistory));
        }
    }
};
