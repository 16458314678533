import { Auth, MultiFactorError, createUserWithEmailAndPassword, getMultiFactorResolver, sendEmailVerification, signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebaseConfigs";
import UserService from "../../services/userService";
import { clearAuthError, doneInitializing, set2FALoginInfo, setAuthError, setAuthIsBusy, setEmailVerificationSent, setOnboarding, setUser } from "../reducers/userReducer";
import { setJobViews as SetJobViews } from "../reducers/viewedJobsReducer";
import { AppDispatch, RootState } from "../store";
import { GetChatHistoryAction } from "./ChatActions";
import { FetchInterviewsAction } from "./InterviewActions";
import { FetchSavedJobsAction } from "./SavedJobsActions";
import { FetchResourceListsAction, SetupSavedResourceBuckets } from "./SavedResourceActions";
import { FetchSavedResumesAction } from "./SavedResumeActions";
import { FetchAllPrompts } from "./ServerPromptActions";
import { FetchModelsAction } from "./FetchModelsActions";
import { AuthType } from "../../auth/AuthFlowContainer";
import { first } from "slate";

export const LogInAction = (auth: Auth, email: string, password: string) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(clearAuthError(undefined));
            dispatch(setAuthIsBusy(true));
            await signInWithEmailAndPassword(auth, email, password);
            const user = await UserService.fetchSelf();
            if (!user) throw new Error('User not found');
            dispatch(setUser(user));
            dispatch(FetchAllPrompts());
            dispatch(GetChatHistoryAction());
            dispatch(FetchResourceListsAction());
            dispatch(FetchInterviewsAction());
            dispatch(FetchSavedResumesAction());
            dispatch(FetchModelsAction());
            dispatch(SetJobViews(user.viewedJobs));
            dispatch(SetupSavedResourceBuckets(user.savedResources));
        } catch (error) {
            let errorMessage = "Something went wrong. Check your info and try again.";
            if (typeof error === 'object' && error !== null && 'code' in error) {
                const firebaseError = error as { code?: string };
                switch (firebaseError.code) {
                    case 'auth/multi-factor-auth-required':
                        const resolver = getMultiFactorResolver(auth, error as MultiFactorError);
                        dispatch(set2FALoginInfo({ resolver }));
                        return;
                    case 'auth/invalid-email':
                        errorMessage = 'Invalid email address';
                        break;
                    case 'auth/user-disabled':
                        errorMessage = 'User account is disabled';
                        break;
                    case 'auth/user-not-found':
                        errorMessage = 'User not found';
                        break;
                    case 'auth/invalid-credential':
                        errorMessage = 'Invalid credentials';
                        break;
                    case 'auth/wrong-password':
                        errorMessage = 'Incorrect password';
                        break;
                    default:
                        errorMessage = 'Unknown error occurred';
                        break;
                }
            }
            dispatch(setAuthError(errorMessage));
            console.error('Error logging in:', error);
        } finally {
            dispatch(setAuthIsBusy(false));
        }
    };
};

export const VerifyEmailAction = () => {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const verificationSent = getState().userState.emailVerifcationSent;
        const user = auth.currentUser;
        if (!verificationSent && user && !user.emailVerified) {
            dispatch(setEmailVerificationSent(true));
            await sendEmailVerification(user, { url: window.location.href });
        }
    };
};

let fetchingUser = false;
export const SetCachedUserAction = () => {
    return async (dispatch: AppDispatch) => {
        if (fetchingUser) return;
        fetchingUser = true;
        try {
            dispatch(setAuthIsBusy(true));
            const user = await UserService.fetchSelf();
            // User doesn't exist in backend
            if (!user) return;
            dispatch(setUser(user));
            dispatch(FetchAllPrompts());
            dispatch(GetChatHistoryAction());
            dispatch(FetchResourceListsAction());
            dispatch(FetchInterviewsAction());
            dispatch(FetchSavedResumesAction());
            dispatch(FetchSavedJobsAction());
            dispatch(FetchModelsAction());
            dispatch(SetJobViews(user.viewedJobs));
            dispatch(SetupSavedResourceBuckets(user.savedResources));
            const hasSkills = user.info?.skills && user.info.skills.length > 0;
            const hasPositions = user.info?.desiredPositions && user.info.desiredPositions.length > 0;
            if (!user.onboarded || !user.info || !hasSkills || !hasPositions) {
                dispatch(setOnboarding({ open: true, type: (!user.onboarded && !user.resume) ? (AuthType.uploadResume) : (!hasSkills ? AuthType.selectSkills : AuthType.selectPositions) }));
            }
        } catch (error) {
            dispatch(setAuthError(`${error}`));
            console.error('Error logging in:', error);
            auth.signOut();
        } finally {
            dispatch(setAuthIsBusy(false));
            dispatch(doneInitializing());
            fetchingUser = false;
        }
    };
};

export const UpdateUserAction = (name: string, marketing: boolean) => {
    return async (dispatch: AppDispatch) => {
        console.log('UpdateUserAction');
        try {
            dispatch(setAuthIsBusy(true));
            console.log('UpdateUserAction');
            const { firstName, lastName } = extractFirstAndLastName(name);
            const updatedUser = await UserService.updateUser({ firstName: firstName, lastName: lastName, marketing });
            dispatch(setOnboarding({ type: AuthType.signUpInfo, open: true }));
            dispatch(setUser(updatedUser));
        } catch (error) {
            let errorMessage = 'Something went wrong. Check your info and try again.';
            console.error(error);
            dispatch(setAuthError(errorMessage));
        } finally {
            dispatch(setAuthIsBusy(false));
        }
    }
}

export const CreateUserAction = (auth: Auth, email: string, password: string, name: string, marketing: boolean) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setOnboarding({ open: true, type: AuthType.signUp }));
            dispatch(setAuthIsBusy(true));
            await createUserWithEmailAndPassword(auth, email, password);
            const { firstName, lastName } = extractFirstAndLastName(name);
            const user = await UserService.createUser({ firstName, lastName, marketing });
            dispatch(setUser(user));
        } catch (error) {
            let errorMessage = 'Something went wrong. Check your info and try again.';
            console.error(error);
            if (typeof error === 'object' && error !== null && 'code' in error) {
                const firebaseError = error as { code?: string };
                switch (firebaseError.code) {
                    case 'auth/email-already-in-use':
                        errorMessage = 'This email is already in use.';
                        break;
                    case 'auth/invalid-email':
                        errorMessage = 'The email address is not valid.';
                        break;
                    case 'auth/operation-not-allowed':
                        errorMessage = 'Email/password accounts are not enabled.';
                        break;
                    case 'auth/weak-password':
                        errorMessage = 'The password is too weak.';
                        break;
                }
            } else if (typeof error === 'string') {
                errorMessage = error;
            }
            dispatch(setAuthError(errorMessage));
            console.error('Error creating user:', error);
        } finally {
            dispatch(setAuthIsBusy(false));
        }
    };
};

const extractFirstAndLastName = (name: string) => {
    let firstName = '';
    let lastName = '';
    if (name.trim() !== '') {
        const nameParts = name.split(' ');
        firstName = nameParts[0];
        lastName = nameParts.slice(1).join(' ');
    }
    return { firstName, lastName };
}