import { ReactNode } from "react";
import { RootState } from "../../redux/store";
import ResumeFixType from "../../shared/enums/ResumeType";
import Job from "../../shared/models/Job";
import Message from "../../shared/models/Message";
import MessageCell from "../components/MessageCell";
import ResumeTypeSelection from "../components/ResumeTypeSelection";
import SavedJobSelection, { SavedJobSelectionType } from "../components/SavedJobSelection";
import { BackendPromptType } from "../../profile/models/BackendPrompt";


export const messageCellForResumeChat = (message: Message, resumeFixType?: ResumeFixType): ReactNode => {
    if (!resumeFixType) {
        return (<>
            <MessageCell
                key={0}
                message={message}
                onBookmarkPressed={() => { }}
                onBookmarkJobPressed={() => { }}
                isNewest={true}
                accessory={<><br /><ResumeTypeSelection /></>}
            />
        </>);
    }
    let accessory: ReactNode | undefined;
    let messageText = '';

    switch (resumeFixType) {
        case ResumeFixType.savedJobs:
            messageText = `Which saved job would you like to optimize your resume for?`;
            accessory = <SavedJobSelection type={SavedJobSelectionType.resume} />;
            break;
        case ResumeFixType.specificRole:
            messageText = `What type of job / role would you like to optimize your resume for?`;
            break;

    }
    return (<>
        <MessageCell
            key={0}
            message={{ ...message, content: messageText }}
            onBookmarkPressed={() => { }}
            onBookmarkJobPressed={() => { }}
            isNewest={true}
            accessory={accessory ? <><br />{accessory}</> : undefined}
            richContent={accessory ? true : false}
        />
    </>);

}


export const titleForResumeFixType = (type: ResumeFixType): string => {
    switch (type) {
        case ResumeFixType.improvements:
            return 'Resume Improvements';
        case ResumeFixType.bestPractices:
            return 'General Resume Best Practices';
        case ResumeFixType.savedJobs:
            return 'Saved Job Optimization';
        case ResumeFixType.specificRole:
            return 'Specific Role Optimization';
    }
}

export const initialMessageForResumeFixType = (type: ResumeFixType): string => {
    switch (type) {
        case ResumeFixType.savedJobs:
            return `Optimize my resume based on one of my saved jobs.`;
        case ResumeFixType.specificRole:
            return `Optimize my resume for a specific role or position that I'm interested in.`;
        default:
            return '';
    }
}

export const resumeStartMessage = (type: ResumeFixType, state: RootState, job?: Job, role?: string): string => {
    switch (type) {
        case ResumeFixType.improvements:
            const prompt = state.promptState.backendPrompts![BackendPromptType.resume][ResumeFixType.improvements];
            return `${prompt.prompt}`;
        case ResumeFixType.bestPractices:
            const prompt2 = state.promptState.backendPrompts![BackendPromptType.resume][ResumeFixType.bestPractices];
            return `${prompt2.prompt}`;
        case ResumeFixType.savedJobs:
            const prompt3 = state.promptState.backendPrompts![BackendPromptType.resume][ResumeFixType.savedJobs];
            return `${prompt3.prompt.replaceAll('[JOB_TITLE]', job?.title ?? '').replaceAll('[JOB_COMPANY]', job?.company ?? '').replaceAll('[JOB_SUMMARY]', job?.summary ?? '')}`;
        case ResumeFixType.specificRole:
            const prompt4 = state.promptState.backendPrompts![BackendPromptType.resume][ResumeFixType.specificRole];
            return `${prompt4.prompt.replaceAll('[SELECTED_ROLE]', role ?? '')}`;
    }
}

export const cleanStartMessage = (messageContent: string): string => {
    let lines = messageContent.includes('\\n\\n') ? messageContent.split('\\n\\n') : messageContent.split('\n\n');
    if (lines.length < 2) {
        return messageContent;
    }
    return lines[1];
}