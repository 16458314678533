import { ReactNode } from "react";
import { generatePromptInfo } from "../../profile/models/UserInfo";
import { RootState } from "../../redux/store";
import UpskillingType from "../../shared/enums/UpskillingType";
import Job from "../../shared/models/Job";
import Message from "../../shared/models/Message";
import MessageCell from "../components/MessageCell";
import SavedJobSelection, { SavedJobSelectionType } from "../components/SavedJobSelection";
import UpskillingTypeSelection from "../components/UpskillingTypeSelection";
import { BackendPromptType } from "../../profile/models/BackendPrompt";


export const messageCellForUpskillingChat = (message: Message, type?: UpskillingType): ReactNode => {
    if (!type) {
        return (<>
            <MessageCell
                key={0}
                message={message}
                onBookmarkPressed={() => { }}
                onBookmarkJobPressed={() => { }}
                isNewest={true}
                accessory={<><br /><UpskillingTypeSelection /></>}
            />
        </>);
    }
    let accessory: ReactNode | undefined;
    let messageText = '';

    switch (type) {
        case UpskillingType.specificRole:
            messageText = `What type of job / role are you looking to upskill for?`;
            break;
        case UpskillingType.savedJobs:
            messageText = `Which saved job would you like to upskill for?`;
            accessory = <SavedJobSelection type={SavedJobSelectionType.upskilling} />;
            break;

    }
    return (<>
        <MessageCell
            key={0}
            message={{ ...message, content: messageText }}
            onBookmarkPressed={() => { }}
            onBookmarkJobPressed={() => { }}
            isNewest={true}
            accessory={accessory ? <><br />{accessory}</> : undefined}
            richContent={accessory ? true : false}
        />
    </>);

}

export const titleForUpskillingType = (type: UpskillingType): string => {
    switch (type) {
        case UpskillingType.resumeAndPreferences:
            return 'Upskill based on Resume and Preferences';
        case UpskillingType.savedJobs:
            return 'Upskill for Saved Job';
        case UpskillingType.specificRole:
            return 'Upskill for Specific Job / Role';
    }
}

export const initialMessageForUpskillType = (type: UpskillingType): string => {
    switch (type) {
        case UpskillingType.specificRole:
            return `I'd like to upskill for a specific job /role.`;
        default:
            return '';
    }
}

export const upskillStartMessage = (type: UpskillingType, state: RootState, job?: Job, role?: string): string => {
    const userInfo = state.userState.currentUser?.info;
    const skills = generatePromptInfo(userInfo);
    switch (type) {
        case UpskillingType.resumeAndPreferences:
            const prompt = state.promptState.backendPrompts![BackendPromptType.upskill][UpskillingType.resumeAndPreferences];
            return `${prompt.prompt}`;
        case UpskillingType.savedJobs:
            const prompt2 = state.promptState.backendPrompts![BackendPromptType.upskill][UpskillingType.savedJobs];
            return `${prompt2.prompt.replaceAll('[JOB_TITLE]', job?.title ?? '').replaceAll('[JOB_COMPANY]', job?.company ?? '').replaceAll('[JOB_SUMMARY]', job?.summary ?? '')}`;
        case UpskillingType.specificRole:
            const prompt3 = state.promptState.backendPrompts![BackendPromptType.upskill][UpskillingType.specificRole];
            console.log('prompt3', prompt3);
            const finalPrompt = `${prompt3.prompt.replaceAll('[SELECTED_ROLE]', role ?? '')}`;
            console.log('finalPrompt', finalPrompt);
            return finalPrompt;
    }
}
