export enum BackendPromptType {
    job = 'job',
    resume = 'resume',
    coverLetter = 'coverLetter',
    upskill = 'upskill',
    interviewPrep = 'interviewPrep',
}

export const cleanBackendPromptType = (type: BackendPromptType): string => {
    switch (type) {
        case BackendPromptType.job:
            return 'job';
        case BackendPromptType.resume:
            return 'resume';
        case BackendPromptType.coverLetter:
            return 'cover_letter';
        case BackendPromptType.upskill:
            return 'upskill';
        case BackendPromptType.interviewPrep:
            return 'interview_prep';
    }
}

export interface BackendPrompt {
    id: number;
    type: BackendPromptType;
    prompt: string;
    ref: string;
}

export default BackendPrompt;