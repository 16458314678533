import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import StopIcon from '../../assets/icons/stop_icon';
import NavigationConstants from '../../navigation/NavigationConstants';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setUserStoppedStreaming } from '../../redux/reducers/appReducer';
import ClickableOpacityDiv from '../../shared/components/ClickableOpacityDiv';
import PPMultiLineTextInput from '../../shared/components/PPMultiLIneTextInput';
import { chatIsInterview } from '../../shared/models/Chat';
import { InterviewState } from '../../shared/models/Interview';
import zIndicies from '../../shared/utils/zIndexConstants';
import { AppColors, BoxShadow, sharpTransition } from '../../theme/AppTheme';
import SendButton from './SendButton';
import { Mic, Stop } from '@mui/icons-material';
import useSpeechToText, { ResultType } from 'react-hook-speech-to-text';
import { Bars } from 'react-loader-spinner';

interface ChatBarProps {
    onAttachPressed: () => void;
    onSubmit: (text: string) => void;
}

const Container = styled(Box)(() => ({
    position: 'fixed',
    bottom: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    zIndex: zIndicies.chatBar,
    transition: `left 0.2s ${sharpTransition}`,
}));

const ChatRow = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderRadius: '20px',
    boxShadow: BoxShadow,
    marginLeft: '33px',
    marginRight: '33px',
    width: 'calc(100% - 46px)',
    marginBottom: '25px',
    paddingLeft: '0px',
    paddingRight: '20px',
    minHeight: '62px',
    maxWidth: '1160px',
    backgroundColor: AppColors.grey.lightest,
}));


export const useIsArcBrowser = () => {
    const [isArc, setIsArc] = useState(false);

    useEffect(() => {
        window.addEventListener("load", function () {
            setTimeout(function () {
                const value = getComputedStyle(document.documentElement)
                    .getPropertyValue('--arc-palette-title')
                console.log(value);
                if (value && !isArc) {
                    console.log('ARC BROWSER DETECTED');
                    setIsArc(true);
                } else if (isArc) {
                    console.log('ARC BROWSER NO LONGER DETECTED');
                    setIsArc(false);
                }
            }, 1000);
        });
    }, []);

    return isArc;
};

const ChatBar = (props: ChatBarProps) => {
    const { t } = useTranslation();
    const isNavOpen = useAppSelector(store => store.appState.drawerIsOpen);
    const isSidebarOpen = useAppSelector(store => store.appState.sidebarIsOpen);
    const isMobile = useAppSelector(store => store.appState.isMobile);
    const activeChat = useAppSelector(store => store.appState.activeChat);
    const dispatch = useAppDispatch();
    const [speechAvailable, setSpeechAvailable] = useState(true);
    const [inputBeforeRecording, setInputBeforeRecording] = useState('');

    const [inputValue, setInputValue] = useState('');
    const isArc = useIsArcBrowser();

    const {
        error,
        interimResult,
        isRecording,
        results,
        startSpeechToText,
        stopSpeechToText,
    } = useSpeechToText({
        continuous: true,
        useLegacyResults: false,
        crossBrowser: true,
        googleApiKey: process.env.REACT_APP_GOOGLE_TTS_API_KEY,
        useOnlyGoogleCloud: isArc,
    });

    if (error) {
        console.error(error);
        setSpeechAvailable(false);
    };

    const handleInputChange = (newValue: string) => {
        setInputValue(newValue);
    }

    const { onAttachPressed, onSubmit } = props;

    const onMessageSubmit = () => {
        if (isRecording) {
            stopSpeechToText();
        }
        if (!!(activeChat?.pending) || !!(activeChat?.streaming))
            return;
        onSubmit(inputValue);
        setInputValue('');
    }

    const onStopMessage = () => {
        dispatch(setUserStoppedStreaming(true));
    }

    const onRecordVoice = () => {
        if (isRecording) {
            stopSpeechToText();
        } else {
            setInputBeforeRecording(inputValue);
            startSpeechToText();
        }
    }

    useEffect(() => {
        const middle = inputBeforeRecording.length > 0 ? ' ' : '';
        setInputValue(inputBeforeRecording + middle + (interimResult ?? ''));
    }, [interimResult, inputBeforeRecording]);

    useEffect(() => {
        if (results.length > 0) {
            const result = results[results.length - 1] as ResultType;
            const addPeriod = (result.transcript?.trim() ?? '') !== '' && !result.transcript.endsWith('.') && !result.transcript.endsWith('?') && !result.transcript.endsWith('!');
            const endPunctuation = addPeriod ? '. ' : '';
            setInputValue((current) => current + (result?.transcript ?? ''));
            setInputBeforeRecording((current) => current + (result?.transcript ?? '') + endPunctuation);
        }
    }, [results]);

    let placeholder = t('messagePlaceholder');
    let isDisabled = !!(activeChat?.pending) || !!(activeChat?.streaming);
    if (activeChat && chatIsInterview(activeChat)) {
        const interview = activeChat.interview!;
        isDisabled = isDisabled || interview.state === InterviewState.completed;
        if (interview.state === InterviewState.completed) {
            placeholder = 'Your interview has concluded. You can save your interview results and start a new interview at any time.'
        }
    }

    return (
        <Container sx={{ left: isMobile ? 0 : (isNavOpen ? NavigationConstants.drawerWidthOpen : NavigationConstants.drawerWidthClosed) + (isSidebarOpen ? NavigationConstants.sidebarWidth : 0) }}>
            <ChatRow>
                {/* <IconButton sx={{ margin: 0 }} onClick={onAttachPressed}>
                    <AttachmentIcon />
                </IconButton> */}
                <PPMultiLineTextInput
                    autofocus={!isMobile}
                    disabled={isDisabled}
                    id='message-input'
                    style={{ paddingLeft: '20px', paddingRight: '34px', marginTop: '15px', marginBottom: '15px', width: 'auto' }}
                    placeholder={placeholder}
                    value={inputValue}
                    onChange={handleInputChange}
                    onEnterPressed={onMessageSubmit}
                    backgroundColor={AppColors.grey.lightest}
                />
                {speechAvailable && !(activeChat?.streaming) &&
                    <ClickableOpacityDiv onClick={onRecordVoice} style={{ marginRight: '24px' }}>
                        {isRecording ?
                            <div style={{ height: '34px', width: '34px', border: `1.5px solid ${AppColors.pink.dark}`, borderRadius: '17px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Bars
                                    height="18"
                                    width="18"
                                    color={AppColors.pink.dark}
                                    ariaLabel="bars-loading"
                                    visible={true}
                                />
                            </div>
                            : <Mic sx={{ color: AppColors.grey.dark }} />}
                    </ClickableOpacityDiv>
                }
                {!!(activeChat?.streaming)
                    ? <ClickableOpacityDiv onClick={onStopMessage}>
                        <StopIcon />
                    </ClickableOpacityDiv>
                    : <div onClick={onMessageSubmit}>
                        <SendButton disabled={inputValue.trim() === '' || !!(activeChat?.pending) || !!(activeChat?.streaming)} />
                    </div>}
            </ChatRow>
        </Container >
    )
}

export default ChatBar