import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import preambleService from '../../services/preambleService';
import suggestedPromptsService from '../../services/suggestedPromptsService';
import BackendPrompt, { BackendPromptType } from '../../profile/models/BackendPrompt';
import { AllPrompts } from '../actions/ServerPromptActions';
import JobMatchType from '../../shared/enums/JobMatchType';
import TopLevelJobMatchType from '../../shared/enums/TopLevelJobMatchType';
import ResumeFixType from '../../shared/enums/ResumeType';
import CoverLetterType from '../../shared/enums/CoverLetterType';
import UpskillingType from '../../shared/enums/UpskillingType';
import InterviewQuestionsType from '../../shared/enums/InterviewQuestionsType';
import { ModelWithType } from '../../services/modelsService';
import { initialModelForKey, modelToString } from '../../shared/models/LLMModels';

interface PromptState {
    interviewPreamble: string;
    interviewPrompt: string;
    interviewModel: ModelWithType;

    resumePrompt: string;
    resumeNameModel: ModelWithType;
    resumeNameToggle: boolean;

    jobMatchPrompt: string;
    resumeOptimizePrompt: string;
    coverLetterPrompt: string;
    upskillPrompt: string;
    mockInterviewPrompt: string;

    backendPrompts?: AllPrompts;
    allModels: ModelWithType[];
}

const initialState: PromptState = {
    interviewModel: initialModelForKey('interviewModel'),
    interviewPreamble: localStorage.getItem('interviewPreamble') ?? preambleService.getInterviewPreamble(),
    interviewPrompt: localStorage.getItem('interviewPrompt') ?? preambleService.getInterviewMessage(),

    resumePrompt: localStorage.getItem('resumePrompt') ?? suggestedPromptsService.getJobPrompts()[1].prompt,
    resumeNameModel: initialModelForKey('resumeNamingModel'),
    resumeNameToggle: false,

    jobMatchPrompt: localStorage.getItem('jobMatchPrompt') ?? suggestedPromptsService.getSuggestedPrompts()[0].prompt,
    resumeOptimizePrompt: localStorage.getItem('resumeOptimizationPrompt') ?? suggestedPromptsService.getSuggestedPrompts()[1].prompt,
    coverLetterPrompt: localStorage.getItem('coverLetterPrompt') ?? suggestedPromptsService.getSuggestedPrompts()[2].prompt,
    upskillPrompt: localStorage.getItem('upskillPrompt') ?? suggestedPromptsService.getSuggestedPrompts()[3].prompt,
    mockInterviewPrompt: localStorage.getItem('mockInterviewPrompt') ?? suggestedPromptsService.getSuggestedPrompts()[5].prompt,

    allModels: [],
};

export const promptsSlice = createSlice({
    name: 'prompts',
    initialState,
    reducers: {
        setInterviewModel: (state, action: PayloadAction<ModelWithType>) => {
            state.interviewModel = action.payload;
            localStorage.setItem('interviewModel', modelToString(action.payload));
        },
        setInterviewPreamble: (state, action: PayloadAction<string>) => {
            state.interviewPreamble = action.payload;
            localStorage.setItem('interviewPreamble', action.payload);
        },
        setInterviewPrompt: (state, action: PayloadAction<string>) => {
            state.interviewPrompt = action.payload;
            localStorage.setItem('interviewPrompt', action.payload);
        },
        resetInterviewPreamble: (state) => {
            state.interviewPreamble = preambleService.getInterviewPreamble();
            localStorage.setItem('interviewPreamble', preambleService.getInterviewPreamble());
        },
        resetInterviewPrompt: (state) => {
            state.interviewPrompt = preambleService.getInterviewMessage();
            localStorage.setItem('interviewPrompt', preambleService.getInterviewMessage());
        },
        setResumePrompt: (state, action: PayloadAction<string>) => {
            state.resumePrompt = action.payload;
            localStorage.setItem('resumePrompt', action.payload);
        },
        resetResumePrompt: (state) => {
            state.resumePrompt = suggestedPromptsService.getJobPrompts()[1].prompt;
            localStorage.setItem('resumePrompt', suggestedPromptsService.getJobPrompts()[1].prompt);
        },
        setResumeModel: (state, action: PayloadAction<ModelWithType>) => {
            state.resumeNameModel = action.payload;
            localStorage.setItem('resumeNamingModel', modelToString(action.payload));
        },
        toggleResumeName: (state) => {
            state.resumeNameToggle = !state.resumeNameToggle;
        },
        setJobMatchPrompt: (state, action: PayloadAction<string>) => {
            state.jobMatchPrompt = action.payload;
            localStorage.setItem('jobMatchPrompt', action.payload);
        },
        resetJobMatchPrompt: (state) => {
            state.jobMatchPrompt = suggestedPromptsService.getSuggestedPrompts()[0].prompt;
            localStorage.setItem('jobMatchPrompt', suggestedPromptsService.getSuggestedPrompts()[0].prompt);
        },
        setResumeOptimizePrompt: (state, action: PayloadAction<string>) => {
            state.resumeOptimizePrompt = action.payload;
            localStorage.setItem('resumeOptimizationPrompt', action.payload);
        },
        setCoverLetterPrompt: (state, action: PayloadAction<string>) => {
            state.coverLetterPrompt = action.payload;
            localStorage.setItem('coverLetterPrompt', action.payload);
        },
        setUpskillPrompt: (state, action: PayloadAction<string>) => {
            state.upskillPrompt = action.payload;
            localStorage.setItem('upskillPrompt', action.payload);
        },
        setMockInterviewPrompt: (state, action: PayloadAction<string>) => {
            state.mockInterviewPrompt = action.payload;
            localStorage.setItem('mockInterviewPrompt', action.payload);
        },
        resetResumeOptimizePrompt: (state) => {
            state.resumeOptimizePrompt = suggestedPromptsService.getSuggestedPrompts()[1].prompt;
            localStorage.setItem('resumeOptimizationPrompt', suggestedPromptsService.getSuggestedPrompts()[1].prompt);
        },
        resetCoverLetterPrompt: (state) => {
            state.coverLetterPrompt = suggestedPromptsService.getSuggestedPrompts()[2].prompt;
            localStorage.setItem('coverLetterPrompt', suggestedPromptsService.getSuggestedPrompts()[2].prompt);
        },
        resetUpskillPrompt: (state) => {
            state.upskillPrompt = suggestedPromptsService.getSuggestedPrompts()[3].prompt;
            localStorage.setItem('upskillPrompt', suggestedPromptsService.getSuggestedPrompts()[3].prompt);
        },
        resetMockInterviewPrompt: (state) => {
            state.mockInterviewPrompt = suggestedPromptsService.getSuggestedPrompts()[5].prompt;
            localStorage.setItem('mockInterviewPrompt', suggestedPromptsService.getSuggestedPrompts()[5].prompt);
        },
        setAllPrompts: (state, action: PayloadAction<AllPrompts>) => {
            state.backendPrompts = action.payload;
        },
        updatePrompt: (state, action: PayloadAction<{ promptId: number; newPrompt: string }>) => {
            const { promptId, newPrompt } = action.payload;

            // Guard: If we have no backendPrompts yet, just exit
            if (!state.backendPrompts) {
                return;
            }

            // Loop through each top-level key in backendPrompts
            for (const promptType of Object.keys(state.backendPrompts) as (keyof AllPrompts)[]) {
                switch (promptType) {
                    case BackendPromptType.job: {
                        // Now TypeScript knows this is the job object: { [JobMatchType.foo]: BackendPrompt; ... }
                        for (const subKey of Object.keys(state.backendPrompts[BackendPromptType.job]) as (JobMatchType | TopLevelJobMatchType)[]) {
                            const promptObj = state.backendPrompts[BackendPromptType.job][subKey as keyof typeof state.backendPrompts[BackendPromptType.job]];
                            if (promptObj.id === promptId) {
                                promptObj.prompt = newPrompt;
                                return;
                            }
                        }
                        break;
                    }
                    case BackendPromptType.resume: {
                        for (const subKey of Object.keys(state.backendPrompts[BackendPromptType.resume]) as ResumeFixType[]) {
                            const promptObj = state.backendPrompts[BackendPromptType.resume][subKey];
                            if (promptObj.id === promptId) {
                                promptObj.prompt = newPrompt;
                                return;
                            }
                        }
                        break;
                    }
                    case BackendPromptType.coverLetter: {
                        for (const subKey of Object.keys(state.backendPrompts[BackendPromptType.coverLetter]) as CoverLetterType[]) {
                            const promptObj = state.backendPrompts[BackendPromptType.coverLetter][subKey];
                            if (promptObj.id === promptId) {
                                promptObj.prompt = newPrompt;
                                return;
                            }
                        }
                        break;
                    }
                    case BackendPromptType.upskill: {
                        for (const subKey of Object.keys(state.backendPrompts[BackendPromptType.upskill]) as UpskillingType[]) {
                            const promptObj = state.backendPrompts[BackendPromptType.upskill][subKey];
                            if (promptObj.id === promptId) {
                                promptObj.prompt = newPrompt;
                                return;
                            }
                        }
                        break;
                    }
                    case BackendPromptType.interviewPrep: {
                        for (const subKey of Object.keys(state.backendPrompts[BackendPromptType.interviewPrep]) as InterviewQuestionsType[]) {
                            const promptObj = state.backendPrompts[BackendPromptType.interviewPrep][subKey];
                            if (promptObj.id === promptId) {
                                promptObj.prompt = newPrompt;
                                return;
                            }
                        }
                        break;
                    }
                }
            }
        },
        setAllModels: (state, action: PayloadAction<ModelWithType[]>) => {
            state.allModels = action.payload;
        }
    },
})

export const {
    setInterviewModel,
    setInterviewPreamble,
    setInterviewPrompt,
    resetInterviewPreamble,
    resetInterviewPrompt,
    setResumePrompt,
    resetResumePrompt,
    setJobMatchPrompt,
    resetJobMatchPrompt,
    setResumeModel,
    toggleResumeName,
    setResumeOptimizePrompt,
    setCoverLetterPrompt,
    setUpskillPrompt,
    setMockInterviewPrompt,
    resetResumeOptimizePrompt,
    resetCoverLetterPrompt,
    resetUpskillPrompt,
    resetMockInterviewPrompt,
    setAllPrompts,
    updatePrompt,
    setAllModels
} = promptsSlice.actions

export default promptsSlice.reducer
