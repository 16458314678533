import styled from '@emotion/styled';
import { Checkbox, Typography } from '@mui/material';
import { AppColors } from '../../theme/AppTheme';

const Container = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '100%',
    maxHeight: '100%',
    overflowY: 'auto',
    boxSizing: 'border-box',
});

const CheckboxContainer = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '100%',
    cursor: 'pointer',
    padding: '0px 15px',
    '&:hover': {
        backgroundColor: AppColors.grey.lightest
    },
    '&:active': {
        opacity: 0.85
    },
    boxSizing: 'border-box',
});

interface MultiCheckListProps {
    items: string[];
    selectedItems: string[];
    onSelectItem: (item: string) => void;
}

const MultiCheckList = (props: MultiCheckListProps) => {
    const { items, selectedItems, onSelectItem } = props;

    return (
        <Container>
            {items.map((item, index) => {
                return (
                    <CheckboxContainer key={index} onClick={() => onSelectItem(item)}>
                        <Checkbox
                            disableRipple
                            checked={selectedItems.includes(item)}
                            color='secondary'
                            sx={{
                                width: '18px',
                                height: '32px',
                                marginRight: '14px',
                                color: AppColors.grey.neutral,
                                '&.Mui-checked': {
                                    color: AppColors.pink.dark,
                                },
                            }} />
                        <Typography variant='body2' style={{ color: AppColors.grey.dark, cursor: 'pointer', fontWeight: 400, lineHeight: 2 }} >{item}</Typography>
                    </CheckboxContainer>
                )
            })}
        </Container>
    )
}

export default MultiCheckList