import { ReactNode } from "react";
import { generatePromptInfo } from "../../profile/models/UserInfo";
import { RootState } from "../../redux/store";
import JobMatchType from "../../shared/enums/JobMatchType";
import Job from "../../shared/models/Job";
import Message from "../../shared/models/Message";
import MessageCell from "../components/MessageCell";
import TopLevelJobMatchTypeSelection from "../components/TopLevelJobMatchTypeSelection";
import { BackendPromptType } from "../../profile/models/BackendPrompt";


export const messageCellForJobMatchChat = (message: Message, jobMatchType?: JobMatchType): ReactNode => {
    if (!jobMatchType) {
        return (<>
            <MessageCell
                key={0}
                message={message}
                onBookmarkPressed={() => { }}
                onBookmarkJobPressed={() => { }}
                isNewest={true}
                accessory={<><br /><TopLevelJobMatchTypeSelection /></>}
            />
        </>);
    }
    let accessory: ReactNode | undefined;
    let messageText = '';

    switch (jobMatchType) {
        case JobMatchType.specificRole:
            messageText = `What type of job / role are you looking for?`;
            break;

    }
    return (<>
        <MessageCell
            key={0}
            message={{ ...message, content: messageText }}
            onBookmarkPressed={() => { }}
            onBookmarkJobPressed={() => { }}
            isNewest={true}
            accessory={accessory ? <><br />{accessory}</> : undefined}
        />
    </>);

}

export const titleForJobMatchType = (type: JobMatchType): string => {
    switch (type) {
        case JobMatchType.resumeAndPreferences:
            return 'Job Match based on Resume and Preferences';
        case JobMatchType.specificRole:
            return 'Specific Job / Role';
    }
}

export const initialMessageForJobMatchType = (type: JobMatchType): string => {
    switch (type) {
        case JobMatchType.specificRole:
            return `Find me jobs for a specific role or position.`;
        default:
            return '';
    }
}

export const jobMatchStartMessage = (type: JobMatchType, state: RootState, unviewed: boolean, job?: Job, role?: string): string => {
    const userInfo = state.userState.currentUser?.info;
    switch (type) {
        case JobMatchType.resumeAndPreferences:
            const skills = generatePromptInfo(userInfo);
            const prompt = state.promptState.backendPrompts![BackendPromptType.job][JobMatchType.resumeAndPreferences];
            return `${prompt.prompt.replaceAll('[USER_SKILLS]', skills)}${unviewed ? 'Only include jobs I haven\'t viewed.' : 'Include jobs I\'ve already viewed.'}`;
        case JobMatchType.specificRole:
            const prompt2 = state.promptState.backendPrompts![BackendPromptType.job][JobMatchType.specificRole];
            return `${prompt2.prompt.replaceAll('[SELECTED_ROLE]', role ?? '')}${unviewed ? 'Only include jobs I haven\'t viewed.' : 'Include jobs I\'ve already viewed.'}`;
    }
}
