import { Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ThreeDots } from 'react-loader-spinner';
import { auth } from '../firebaseConfigs';
import { Strings } from '../i18n';
import { LogoutAction } from '../redux/actions/GlobalActions';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { setOnboarding, setTwoFactorOpen } from '../redux/reducers/userReducer';
import ClickableOpacityDiv from '../shared/components/ClickableOpacityDiv';
import PrimaryButton from '../shared/components/PrimaryButton';
import { AppColors } from '../theme/AppTheme';
import AuthModalDiv from './components/AuthModalDiv';

interface VerifyEmailScreenProps {
    onEmailVerified: () => void;
}

const VerifyEmailScreen = (props: VerifyEmailScreenProps) => {
    const { t } = useTranslation();
    const isMobile = useAppSelector((s) => s.appState.isMobile);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const dispatch = useAppDispatch();


    const onLoginPressed = () => {
        dispatch(setOnboarding({ open: true }));
        dispatch(LogoutAction());
    }

    const checkVerification = async () => {
        setError('');
        setLoading(true);
        await auth.currentUser?.reload();
        if (auth.currentUser?.emailVerified) {
            dispatch(setTwoFactorOpen(true));
            auth.currentUser.getIdToken(true);
            props.onEmailVerified();
        } else {
            setError('Email is not verified yet');
        }
        setLoading(false);
    }

    return (
        <AuthModalDiv isMobile={isMobile}>
            <Typography variant='h2' style={{ marginBottom: '21px' }}>
                {t(Strings.verifyEmailTitle)}
            </Typography>
            <Typography variant='h5' style={{
                textAlign: 'center',
                marginBottom: '40px',
                color: AppColors.grey.darkish
            }}>
                {t(Strings.verifyEmailSubtitle)}
            </Typography>
            <Typography variant='h5' style={{ lineHeight: '25px', marginTop: '0px', display: 'flex', flexDirection: 'column', justifyContent: 'center', flexWrap: 'wrap', textAlign: 'center' }}>
                <Typography variant='h5' style={{ color: AppColors.grey.darkish, lineHeight: '25px', marginRight: '6px', marginBottom: '16px' }}>
                    Sent to:
                </Typography>
                {auth.currentUser?.email}
            </Typography>
            <PrimaryButton height={'50px'} disabled={loading} title={'Check verification'} onClick={checkVerification} style={{ width: '200px', marginTop: '32px' }} />
            {error && error.length > 0 && !loading &&
                <Typography variant='subtitle2' style={{ color: AppColors.error }}>
                    {error}
                </Typography>
            }
            <ThreeDots
                height="30"
                width="30"
                color={AppColors.pink.dark}
                ariaLabel="dots-loading"
                visible={loading}
            />
            <Typography variant='h5' style={{ color: AppColors.grey.darkish, marginTop: '60px', display: 'flex', flexDirection: 'row', justifyContent: 'center', flexWrap: 'wrap', textAlign: 'center' }}>
                Wrong email?
                <ClickableOpacityDiv onClick={onLoginPressed} style={{ color: AppColors.link, marginLeft: '3px' }}>Go back and create a new account.</ClickableOpacityDiv>
            </Typography>
        </AuthModalDiv>
    )
}

export default VerifyEmailScreen;