import { ReactNode } from "react";
import CoverLetTypeSelection from "../../chat/components/CoverLetTypeSelection";
import InterviewQuestionsTypeSelection from "../../chat/components/InterviewQuestionsTypeSelection";
import ResumeTypeSelection from "../../chat/components/ResumeTypeSelection";
import UpskillingTypeSelection from "../../chat/components/UpskillingTypeSelection";
import { coverLetterPromptMessage } from "../../redux/actions/CoverLetterActions";
import { interviewQuestionsPromptMessage } from "../../redux/actions/InterviewQuestionsActions";
import { jobPromptMessage } from "../../redux/actions/JobMatchActions";
import { resumePromptMessage } from "../../redux/actions/SavedResumeActions";
import { upskillPromptMessage } from "../../redux/actions/UpskillingActions";
import Message, { MessageType } from "../models/Message";
import { topLevelJobPromptMessage } from "../../redux/actions/TopLevelJobMatchActions";
import TopLevelJobMatchTypeSelection from "../../chat/components/TopLevelJobMatchTypeSelection";

enum PromptType {
    jobMatch = 'JOB_MATCH',
    jobAdvice = 'JOB_ADVICE',
    upskilling = 'UPSKILLING',
    resume = 'RESUME',
    coverLetter = 'COVER_LETTER',
    interview = 'INTERVIEW',
}

export const messageForPromptType = (type: PromptType): Message => {
    const content = contentForMessage(type);
    return {
        content,
        dt: (new Date()).toISOString(),
        type: MessageType.received,
    }
}

const contentForMessage = (type: PromptType): string => {
    switch (type) {
        case PromptType.jobAdvice:
            return topLevelJobPromptMessage;
        case PromptType.jobMatch:
            return jobPromptMessage;
        case PromptType.upskilling:
            return upskillPromptMessage;
        case PromptType.resume:
            return resumePromptMessage;
        case PromptType.coverLetter:
            return coverLetterPromptMessage;
        case PromptType.interview:
            return interviewQuestionsPromptMessage;
    }
}

export const accessoryForPromptType = (type: PromptType): ReactNode => {
    switch (type) {
        case PromptType.jobAdvice:
            return <><br /><TopLevelJobMatchTypeSelection /></>;
        case PromptType.jobMatch:
            return <><br /><TopLevelJobMatchTypeSelection /></>;
        case PromptType.upskilling:
            return <><br /><UpskillingTypeSelection /></>;
        case PromptType.resume:
            return <><br /><ResumeTypeSelection /></>;
        case PromptType.coverLetter:
            return <><br /><CoverLetTypeSelection /></>;
        case PromptType.interview:
            return <><br /><InterviewQuestionsTypeSelection /></>;
    }
}

export default PromptType;