import { ReactNode } from "react";
import { RootState } from "../../redux/store";
import InterviewQuestionsType from "../../shared/enums/InterviewQuestionsType";
import Job from "../../shared/models/Job";
import Message from "../../shared/models/Message";
import InterviewQuestionsTypeSelection from "../components/InterviewQuestionsTypeSelection";
import MessageCell from "../components/MessageCell";
import SavedJobSelection, { SavedJobSelectionType } from "../components/SavedJobSelection";
import { BackendPromptType } from "../../profile/models/BackendPrompt";


export const messageCellForInterviewQuestionsChat = (message: Message, type?: InterviewQuestionsType): ReactNode => {
    if (!type) {
        return (<>
            <MessageCell
                key={0}
                message={message}
                onBookmarkPressed={() => { }}
                onBookmarkJobPressed={() => { }}
                isNewest={true}
                accessory={<><br /><InterviewQuestionsTypeSelection /></>}
            />
        </>);
    }
    let accessory: ReactNode | undefined;
    let messageText = '';

    switch (type) {
        case InterviewQuestionsType.job:
            messageText = `Which saved job would you like interview questions for?`;
            accessory = <SavedJobSelection type={SavedJobSelectionType.interviewQuestions} />;
            break;
        case InterviewQuestionsType.specified:
            messageText = `What type of job / role would you like interview questions for?`;
            break;

    }
    return (<>
        <MessageCell
            key={0}
            message={{ ...message, content: messageText }}
            onBookmarkPressed={() => { }}
            onBookmarkJobPressed={() => { }}
            isNewest={true}
            accessory={accessory ? <><br />{accessory}</> : undefined}
            richContent={accessory ? true : false}
        />
    </>);

}

export const titleForInterviewQuestionsType = (type: InterviewQuestionsType): string => {
    switch (type) {
        case InterviewQuestionsType.resume:
            return 'Interview Prep Based on Resume';
        case InterviewQuestionsType.job:
            return 'Saved Job Interview Prep';
        case InterviewQuestionsType.specified:
            return 'Specific Role Interview Prep';
    }
}

export const initialMessageForInterviewQuestionsType = (type: InterviewQuestionsType): string => {
    switch (type) {
        case InterviewQuestionsType.job:
            return `I'd like interview questions to prepare for one of my saved jobs.`;
        case InterviewQuestionsType.specified:
            return `I'd like interview questions to prepare for a specific role or position that I'm interested in.`;
        default:
            return '';
    }
}

export const interviewQuestionsMessage = (type: InterviewQuestionsType, state: RootState, job?: Job, role?: string): string => {
    switch (type) {
        case InterviewQuestionsType.resume:
            const prompt = state.promptState.backendPrompts![BackendPromptType.interviewPrep][InterviewQuestionsType.resume];
            return `${prompt.prompt}`;
        case InterviewQuestionsType.job:
            const prompt2 = state.promptState.backendPrompts![BackendPromptType.interviewPrep][InterviewQuestionsType.job];
            return `${prompt2.prompt.replaceAll('[JOB_TITLE]', job?.title ?? '').replaceAll('[JOB_COMPANY]', job?.company ?? '').replaceAll('[JOB_SUMMARY]', job?.summary ?? '')}`;
        case InterviewQuestionsType.specified:
            const prompt3 = state.promptState.backendPrompts![BackendPromptType.interviewPrep][InterviewQuestionsType.specified];
            return `${prompt3.prompt.replaceAll('[SELECTED_ROLE]', role ?? '')}`;
    }
}
