import { ReactNode } from "react";
import { RootState } from "../../redux/store";
import Job from "../../shared/models/Job";
import Message from "../../shared/models/Message";
import MessageCell from "../components/MessageCell";
import TopLevelJobMatchType from "../../shared/enums/TopLevelJobMatchType";
import TopLevelJobMatchTypeSelection from "../components/TopLevelJobMatchTypeSelection";
import { BackendPromptType } from "../../profile/models/BackendPrompt";


export const messageCellForTopLevelJobMatchChat = (message: Message, jobMatchType?: TopLevelJobMatchType): ReactNode => {
    if (!jobMatchType) {
        return (<>
            <MessageCell
                key={0}
                message={message}
                onBookmarkPressed={() => { }}
                onBookmarkJobPressed={() => { }}
                isNewest={true}
                accessory={<><br /><TopLevelJobMatchTypeSelection /></>}
            />
        </>);
    }
    let accessory: ReactNode | undefined;
    let messageText = '';

    return (<>
        <MessageCell
            key={0}
            message={{ ...message, content: messageText }}
            onBookmarkPressed={() => { }}
            onBookmarkJobPressed={() => { }}
            isNewest={true}
            accessory={accessory ? <><br />{accessory}</> : undefined}
        />
    </>);

}

export const titleForTopLevelJobMatchType = (type: TopLevelJobMatchType): string => {
    switch (type) {
        case TopLevelJobMatchType.advice:
            return 'Job Advice';
        default:
            return '';
    }
}

export const initialMessageForTopLevelJobMatchType = (type: TopLevelJobMatchType): string => {
    switch (type) {
        default:
            return '';
    }
}

export const topLevelJobMatchStartMessage = (type: TopLevelJobMatchType, state: RootState, job?: Job, role?: string): string => {
    const userInfo = state.userState.currentUser?.info;
    switch (type) {
        case TopLevelJobMatchType.advice:
            const prompt = state.promptState.backendPrompts![BackendPromptType.job][TopLevelJobMatchType.advice];
            return `${prompt.prompt.replaceAll('[USER_RESUME]', state.userState.currentUser?.resume?.textResume || '').replaceAll('[USER_SKILLS]', (userInfo?.skills?.join(',') ?? ''))}`;
        default:
            return '';
    }
}
