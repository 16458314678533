import { auth } from "../firebaseConfigs";
import SavedResume, { SavedResumeType } from "../mainTabs/savedResumes/model/SavedResume";
import { ModelWithType } from "./modelsService";

class ResumeService {
    static generateResumeName = async (model: ModelWithType, message: string, signal: AbortSignal, jobName?: string): Promise<string | undefined> => {
        try {
            const token = await auth.currentUser?.getIdToken();
            const res = await fetch(`${process.env.REACT_APP_API_URL}/resumes/name`, {
                method: 'POST',
                signal: signal,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ ...model, message, jobName }),
            });

            if (res.status !== 200) {
                console.error(`Failed to name resume: ${res.status}`);
            }
            const data = await res.json();
            const messages = data["messages"];
            if (messages && messages.length > 1) {
                const content = messages[1]["content"];
                const contentWithoutQuotes = content.replace(/^'|'$/g, '');
                return contentWithoutQuotes;
            }
            return "";
        } catch (error) {
            console.error('Error generating resume name:', error);
        }
    };

    static fetchSavedResumes = async (): Promise<SavedResume[]> => {
        try {
            const token = await auth.currentUser?.getIdToken();
            const res = await fetch(`${process.env.REACT_APP_API_URL}/savedresumes/`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (res.status !== 200) {
                console.error(`Failed to get saved resumes: ${res.status}`);
            }
            const json: SavedResume[] = await res.json();
            return json;
        } catch (error) {
            console.error('Error getting saved resumes:', error);
        }
        return [];
    }

    static saveResume = async (title: string, content: string, type: SavedResumeType): Promise<SavedResume | undefined> => {
        try {
            const token = await auth.currentUser?.getIdToken();
            const savedTitle = (!title || title.trim() === '') ? (type === SavedResumeType.resume ? 'My Resume' : 'My Cover Letter') : title;
            const endpoint = type === SavedResumeType.resume ? 'savedresumes/save' : 'savedresumes/cover-letters/save';
            const res = await fetch(`${process.env.REACT_APP_API_URL}/${endpoint}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    title: savedTitle,
                    content: content,
                    type: type,
                }),
            });

            const data: SavedResume = await res.json();
            return data;
        } catch (error) {
            console.error('Error saving resume:', error);
        }
    }

    static unsaveResume = async (resume: SavedResume) => {
        try {
            const token = await auth.currentUser?.getIdToken();

            const res = await fetch(`${process.env.REACT_APP_API_URL}/savedresumes/remove/${resume.id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (res.status !== 200) {
                console.error(`Failed to unsave resume: ${res.status}`);
            }
        } catch (error) {
            console.error('Error unsaving resume:', error);
        }
    }

    static updateResume = async (resumeId: number, title: string, content: string): Promise<SavedResume | undefined> => {
        try {
            const token = await auth.currentUser?.getIdToken();

            const res = await fetch(`${process.env.REACT_APP_API_URL}/savedresumes/update/${resumeId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ title, content }),
            });

            if (res.status !== 200) {
                console.error(`Failed to update resume title: ${res.status}`);
                return;
            }

            const data: SavedResume = await res.json();
            return data;

        } catch (error) {
            console.error('Error updating resume title:', error);
        }
    }
}

export default ResumeService;
