
import styled from '@emotion/styled';
import { Rating, Typography } from '@mui/material';
import parse from 'html-react-parser';
import { useEffect, useRef, useState } from 'react';
import CaretLeftIcon from '../../assets/icons/caret_left_icon';
import CloseIcon from '../../assets/icons/close_icon';
import SavedIcon from '../../assets/icons/save_icon_selected';
import SaveIcon from '../../assets/icons/save_icon_unselected';
import EmptyStar from '../../assets/images/empty_star.png';
import Star from '../../assets/images/star.png';
import SavedResourceListDesktopModal from '../../mainTabs/savedResources/SavedResourceListDesktopModal';
import SavedResourceListMobileModal from '../../mainTabs/savedResources/SavedResourceListMobileModal';
import { ClearAllFocusedAction } from '../../redux/actions/GlobalActions';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { addResourceToList, addSavedResource, removeSavedResource, toggleResources } from '../../redux/reducers/resourceReducer';
import ResourcesService from '../../services/resourcesService';
import ClickableOpacityDiv from '../../shared/components/ClickableOpacityDiv';
import ExpandButton from '../../shared/components/ExpandButton';
import NotificationBar, { NotificationType } from '../../shared/components/NotificationBar';
import OutlinedButton from '../../shared/components/OutlinedButton';
import PrimaryButton from '../../shared/components/PrimaryButton';
import Resource, { ResourceData, ResourceType } from '../../shared/models/Resource';
import { isIOS } from '../../shared/utils/PlatformUtils';
import { hostnameFromUrl } from '../../shared/utils/StringUtils';
import zIndicies from '../../shared/utils/zIndexConstants';
import { AppColors, sharpTransition } from '../../theme/AppTheme';
import ReviewCard from '../components/ReviewCard';


const DetailsPage = styled('div')((props: { position: number, fixed: boolean, isMobile: boolean }) => ({
    position: props.fixed ? 'absolute' : undefined,
    right: props.fixed ? -props.position : undefined,
    top: props.fixed ? 0 : undefined,
    bottom: props.fixed ? 0 : undefined,
    backgroundColor: AppColors.white,
    transition: `right 0.2s ${sharpTransition}`,
    width: props.isMobile ? props.fixed ? '98%' : '100%' : (props.fixed ? 'clamp(500px, 55vw, 1200px)' : '100%'),
    overflowY: 'hidden',
    height: '100%',
    maxHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
}));

const ScrollableContainer = styled('div')((props: { isMobile: boolean }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingLeft: props.isMobile ? '40px' : 'clamp(24px, 6vw, 145px)',
    paddingRight: props.isMobile ? '40px' : 'clamp(24px, 6vw, 145px)',
    paddingTop: props.isMobile ? '30px' : '80px',
    alignItems: 'center',
    width: props.isMobile ? 'auto' : '100%',
    minWidth: props.isMobile ? '0' : '100%',
    height: props.isMobile ? 'calc(100% - 350px)' : 'calc(100% - 69px)',
    maxHeight: '100%',
    overflowY: 'auto',
    paddingBottom: '200px',
}));

const StyledButtons = styled('div')((props: { isMobile: boolean }) => ({
    position: 'absolute',
    bottom: 0,
    padding: '10px',
    borderTop: `1px solid ${AppColors.grey.border}`,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '15px',
    width: props.isMobile ? 'calc(100% - 20px)' : '100%',
    backgroundColor: AppColors.white,
    marginBottom: 'env(safe-area-inset-bottom)',
}));

const Divider = () => {
    return <div style={{ width: '100%', minHeight: '1.5px', backgroundColor: AppColors.separator }} />
}

const StyledLI = styled('li')(() => ({
    color: AppColors.grey.darkish,
    textAlign: 'left',
    marginBottom: '12px',
}));

const StyledTopBar = styled('div')((props: { paddingTop?: string }) => ({
    position: 'sticky',
    top: 0,
    left: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: props.paddingTop ?? '34px',
    paddingBottom: '10px',
    paddingLeft: '34px',
    paddingRight: '34px',
    backgroundColor: AppColors.white,
    zIndex: zIndicies.resourceFlyoutTopBar,
    transition: 'padding-top 0.2s ease-in-out',
}));

interface ResourceDetailsContentPageProps {
    resource: Resource;
    animated: boolean;
    hasBackButton?: boolean;
    onClose?: () => void;
}

const ResourceDetailsContentPage = (props: ResourceDetailsContentPageProps) => {
    const { resource, onClose, animated, hasBackButton } = props;
    const [position, setPosition] = useState(animated ? 800 : 0);
    const savedResources = useAppSelector(store => store.resourceState.savedResources ?? {});
    const savedResourcesArray = Object.values(savedResources).flat();
    const isMobile = useAppSelector(store => store.appState.isMobile);
    const savedResourceToggle = useAppSelector(store => store.resourceState.resourceToggle);
    const isSaved = savedResourcesArray.some(j => j.url === resource.url);
    const [bookmarked, setBookmarked] = useState(isSaved);
    const [listModalOpen, setListModalOpen] = useState(false);
    const [notificationBarOpen, setNotificationBarOpen] = useState(false);
    const [isEditingLists, setIsEditingLists] = useState(false);
    const resourceLists = useAppSelector(store => store.resourceState.resourceList);
    const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);
    const [shouldCloseNotification, setShouldCloseNotification] = useState(false);
    const [topBarFull, setTopBarFull] = useState(true);

    const containerRef = useRef<HTMLDivElement | null>(null);

    const dispatch = useAppDispatch();

    useEffect(() => {
        setPosition(0);
    }, []);

    useEffect(() => {
        const isSaved = savedResourcesArray.some(j => j.url === resource.url);
        if (isSaved !== bookmarked) {
            setBookmarked(isSaved);
        }
    }, [savedResourceToggle, savedResourcesArray, resource.url]);

    useEffect(() => {
        if (!animated || !isMobile) return;
        const handleScroll = () => {
            const isIos = isIOS();
            if (containerRef.current) {
                const scrollTop = isIos
                    ? -(document.getElementById('innerContainer')?.getBoundingClientRect().top ?? 0)
                    : containerRef.current.scrollTop;
                // Handle top bar grow/shrink
                if (!topBarFull && scrollTop < 20) {
                    setTopBarFull(true);
                } else if (topBarFull && scrollTop >= 20) {
                    setTopBarFull(false);
                }
            }
        };

        if (containerRef.current) {
            containerRef.current.addEventListener('scroll', handleScroll);
            containerRef.current.addEventListener('touchmove', handleScroll);
        }

        return () => {
            if (!animated || !isMobile) return;
            if (containerRef.current) {
                containerRef.current.removeEventListener('scroll', handleScroll);
                containerRef.current.removeEventListener('touchmove', handleScroll);
            }
        };
    }, [topBarFull]);

    const close = () => {
        if (onClose)
            onClose();
        if (animated) {
            setPosition(800);
            setTimeout(() => {
                dispatch(ClearAllFocusedAction());
            }, 200);
        } else {
            dispatch(ClearAllFocusedAction());
        }
    }

    const onView = () => {
        window.open(resource.url, '_blank');
    }

    const onDesktopModalClosed = async (shouldDelete: boolean) => {
        setListModalOpen(false);
        setShouldCloseNotification(true);
        if (shouldDelete) {
            await ResourcesService.unsaveResource(resource)
            dispatch(removeSavedResource(resource));
            setBookmarked(false);
            dispatch(toggleResources());
        }
    }

    const onBookmark = async () => {
        if (!bookmarked) {
            setBookmarked(true);
            const savedResource = await ResourcesService.saveResource(resource);
            if (savedResource) {
                dispatch(addSavedResource(savedResource));
                const favouritesList = resourceLists.find(list => list.name === 'Likes');
                if (favouritesList) {
                    const success = await ResourcesService.addResourceToList(favouritesList.id, savedResource.url);
                    if (success) {
                        dispatch(addResourceToList({ listId: favouritesList.id, resource: savedResource }));
                        setIsEditingLists(false);
                        setNotificationBarOpen(true);
                    }
                } else {
                    console.error("Couldn't find likes list");
                }
            }
            if (!savedResource) {
                setBookmarked(false);
            }
        } else {
            setAnchorElement(document.getElementById('resource-save-btn'));
            setIsEditingLists(true);
            setListModalOpen(true);
        }
    }

    const onShowLists = () => {
        setAnchorElement(document.getElementById('action-btn'));
        setListModalOpen(true);
    }

    const showTopControls = !isMobile || (isMobile && animated);

    return (
        <DetailsPage position={position} fixed={animated} isMobile={isMobile}>
            <div style={{ height: '100%', width: '100%', position: 'relative' }}>
                {showTopControls && <StyledTopBar paddingTop={isMobile && !topBarFull ? '15px' : undefined}>
                    {animated && <ClickableOpacityDiv style={{
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor: AppColors.white,
                    }}
                        onClick={close}>
                        <CloseIcon />
                    </ClickableOpacityDiv>}
                    {!animated && hasBackButton && <ClickableOpacityDiv style={{
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor: AppColors.white,
                    }}
                        onClick={close}>
                        <CaretLeftIcon />
                        <span style={{ marginLeft: '10px' }}>Back</span>
                    </ClickableOpacityDiv>}

                    <ClickableOpacityDiv
                        id={'resource-save-btn'}
                        onClick={onBookmark}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        {bookmarked ? <SavedIcon /> : <SaveIcon />}
                    </ClickableOpacityDiv>
                </StyledTopBar>}
                <ScrollableContainer ref={containerRef} isMobile={isMobile}>
                    {/* Header */}
                    <div style={{ maxWidth: '100%' }}>
                        <img src={resource.imageUrl} style={{ objectFit: 'cover', width: '100%', aspectRatio: '2.26', marginTop: '0px' }} alt={resource.title} />
                        <Typography variant='h2' style={{
                            whiteSpace: 'normal', wordBreak: 'break-word', color: AppColors.black, fontSize: isMobile ? '18px' : '24px', marginTop: isMobile ? '30px' : '60px', lineHeight: isMobile ? '30px' : 'auto', marginBottom: '16px', maxLines: 4,
                        }}>
                            {resource.title}
                        </Typography>
                        <Typography variant='h2' style={{
                            maxWidth: '100%', whiteSpace: 'normal', wordBreak: 'break-word', fontSize: isMobile ? '14px' : '20px', fontWeight: '300', lineHeight: isMobile ? '26px' : '36px'
                        }}>
                            {resource.description}
                        </Typography>
                        <Typography variant='h3' style={{ color: AppColors.grey.dark, marginTop: '30px', marginBottom: '32px', fontWeight: '400' }}>
                            {resource.rating && <Rating
                                name="read-only-rating"
                                value={parseFloat(resource.rating ?? '0')}
                                precision={0.1}
                                icon={<img style={{ marginRight: '6px' }} width={20} height={20} src={Star} alt='star' />}
                                emptyIcon={<img style={{ marginRight: '6px' }} width={20} height={20} src={EmptyStar} alt='star' />}
                                readOnly
                            />}
                        </Typography>
                        {resource.price && <Typography variant='h2' style={{ fontSize: isMobile ? '18px' : '20px', marginBottom: '22px', fontWeight: '300' }}>
                            {resource.price}
                        </Typography>}
                        <Typography variant='h5' style={{ fontSize: isMobile ? '14px' : '16px', color: AppColors.grey.darkish, marginBottom: isMobile ? '25px' : '60px', lineHeight: isMobile ? '24px' : 'auto' }}>
                            {hostnameFromUrl(resource.url)}
                        </Typography>
                        <Divider />
                        {resource.body && resource.body.map((section, index) => {
                            const isLast = index === resource.body.length - 1;
                            return <div key={section.title}>
                                <Typography variant='body2' style={{ fontSize: isMobile ? '12px' : '16px', width: '100%', textAlign: 'left', marginTop: isMobile ? '39px' : '56px', marginBottom: '39px' }}>
                                    {section.title}
                                </Typography>

                                <ResourceBody resource={section} />
                                {(!isLast || resource.reviews?.length > 0) && <Divider />}
                            </div>
                        })}
                        {resource.reviews?.length > 0 &&
                            <>
                                <Typography variant='body2' style={{ width: '100%', textAlign: 'left', marginTop: '56px', marginBottom: '39px' }}>
                                    Reviews
                                </Typography>
                                {resource.reviews.map((review, index) => {
                                    return <ReviewCard key={index} review={review} />
                                })}
                            </>
                        }
                    </div>
                </ScrollableContainer>
                <StyledButtons isMobile={isMobile}>
                    <PrimaryButton title={'View'} disabled={false} onClick={onView} height={'48px'} style={{ width: '118px' }} />
                    <OutlinedButton title={bookmarked ? 'Unsave' : 'Save'} disabled={false} onClick={onBookmark} height={'48px'} style={{ width: '118px' }} />
                </StyledButtons>
                {notificationBarOpen && <NotificationBar
                    type={NotificationType.resource}
                    marginTop={animated ? (topBarFull ? 67 : 48) : 100}
                    onClose={() => {
                        setShouldCloseNotification(false);
                        setNotificationBarOpen(false);
                    }}
                    shouldClose={shouldCloseNotification}
                    onAction={onShowLists}
                />}
                {!isMobile && listModalOpen &&
                    <SavedResourceListDesktopModal
                        backgroundClicked={onDesktopModalClosed}
                        savedResource={resource}
                        topRight={isEditingLists}
                        anchorEl={anchorElement}
                    />
                }
                {isMobile && listModalOpen &&
                    <SavedResourceListMobileModal
                        backgroundClicked={onDesktopModalClosed}
                        savedResource={resource}
                    />
                }
            </div>
        </DetailsPage >
    )
}

const ResourceBody = (props: { resource: ResourceData }) => {
    const { resource } = props;
    const closedHeight = 235;
    const isMobile = useAppSelector(store => store.appState.isMobile);
    const [isExpanded, setIsExpanded] = useState(true);
    const [hasExpandButton, setHasExpandButton] = useState(false);
    const [contentHeight, setContentHeight] = useState(closedHeight);
    const [initialHeightSet, setInitialHeightSet] = useState(false);
    const contentRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (contentRef.current && !initialHeightSet) {
            setInitialHeightSet(true);
            setContentHeight(contentRef.current.clientHeight);
            if (contentRef.current.clientHeight > (closedHeight * (resource.type === ResourceType.doubleColumn ? 2 : 1))) {
                setIsExpanded(false);
                setHasExpandButton(true);
            }
        }
    }, [resource.data, isExpanded]);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    switch (resource.type) {
        case ResourceType.html:
            return <div >
                <div ref={contentRef}>
                    <Typography variant='h5'
                        style={{
                            fontSize: isMobile ? '14px' : '16px',
                            lineHeight: isMobile ? '26px' : '24px',
                            color: AppColors.grey.darkish,
                            marginBottom: hasExpandButton ? '25px' : '59px',
                            textAlign: 'left',
                            maxHeight: (isExpanded || !initialHeightSet) ? 'none' : `${closedHeight}px`,
                            overflow: 'hidden',
                        }}
                    >
                        {parse(resource.data)}
                    </Typography>
                </div>
                {hasExpandButton && <ExpandButton onClick={toggleExpand} isExpanded={isExpanded} />}
            </div>
        case ResourceType.column:
            return <div>
                <div ref={contentRef}>
                    <Typography variant='h5' style={{
                        color: AppColors.grey.darkish, marginBottom: '47px', textAlign: 'left', fontSize: isMobile ? '14px' : '16px',
                        lineHeight: isMobile ? '26px' : '24px',
                    }}>
                        <ul>
                            {resource.data.map((item: string, index: number) => {
                                return <StyledLI key={index}>{item}</StyledLI>
                            })}
                        </ul>
                    </Typography>
                </div>
                {hasExpandButton && <ExpandButton onClick={toggleExpand} isExpanded={isExpanded} />}
            </div>

        case ResourceType.doubleColumn:
            if (isMobile) {
                return <div>
                    <div ref={contentRef}>
                        <Typography variant='h5' style={{
                            color: AppColors.grey.darkish, marginBottom: '47px', textAlign: 'left', fontSize: isMobile ? '14px' : '16px',
                            lineHeight: isMobile ? '26px' : '24px',
                        }}>
                            <ul>
                                {resource.data.map((item: string, index: number) => {
                                    return <StyledLI key={index}>{item}</StyledLI>
                                })}
                            </ul>
                        </Typography>
                    </div>
                    {hasExpandButton && <ExpandButton onClick={toggleExpand} isExpanded={isExpanded} />}
                </div>
            }
            return (
                <div>
                    <div ref={contentRef}>
                        <Typography ref={contentRef} variant='h5' style={{
                            color: AppColors.grey.darkish, marginBottom: '47px', textAlign: 'left', fontSize: isMobile ? '14px' : '16px',
                            lineHeight: isMobile ? '26px' : '24px',
                        }}>
                            <ul style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', columnGap: '20px' }}>
                                {resource.data.map((item: string, index: number) => (
                                    <StyledLI key={index}>{item}</StyledLI>
                                ))}
                            </ul>
                        </Typography>
                    </div>
                    {hasExpandButton && <ExpandButton onClick={toggleExpand} isExpanded={isExpanded} />}
                </div>
            );
        default:
            return <div ref={contentRef}>Default</div>
    }
}


export default ResourceDetailsContentPage