import { auth } from "../firebaseConfigs";
import BackendPrompt, { cleanBackendPromptType } from "../profile/models/BackendPrompt";
import PromptType from "../shared/enums/PromptType";
import Job from "../shared/models/Job";
import JobDescription from "../shared/models/JobDescription";

class PromptsService {
    static updatePrompt = async (promptId: number, newPrompt: string): Promise<BackendPrompt | undefined> => {
        try {
            const token = await auth.currentUser?.getIdToken();

            const res = await fetch(`${process.env.REACT_APP_API_URL}/prompts/${promptId}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ prompt: newPrompt }),
            });

            const data: BackendPrompt = await res.json();
            return data;
        } catch (error) {
            console.error('Error updating prompt:', error);
        }
    };

    static resetPrompt = async (prompt: BackendPrompt): Promise<BackendPrompt | undefined> => {
        try {
            const token = await auth.currentUser?.getIdToken();

            const res = await fetch(`${process.env.REACT_APP_API_URL}/prompts/${prompt.id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ promptRef: prompt.ref, promptType: cleanBackendPromptType(prompt.type) }),
            });

            const data: BackendPrompt = await res.json();
            return data;
        } catch (error) {
            console.error('Error resetting prompt:', error);
        }
    };

    static fetchAllPrompts = async (): Promise<BackendPrompt[]> => {
        try {
            const token = await auth.currentUser?.getIdToken();
            const res = await fetch(`${process.env.REACT_APP_API_URL}/prompts/`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (res.status !== 200) {
                console.error(`Failed to get saved jobs: ${res.status}`);
            }
            const json: BackendPrompt[] = await res.json();
            return json;
        } catch (error) {
            console.error('Error getting saved jobs:', error);
        }
        return [];
    }


}

export default PromptsService;
