import { ReactNode } from "react";
import { RootState } from "../../redux/store";
import CoverLetterType from "../../shared/enums/CoverLetterType";
import Job from "../../shared/models/Job";
import Message from "../../shared/models/Message";
import CoverLetTypeSelection from "../components/CoverLetTypeSelection";
import MessageCell from "../components/MessageCell";
import SavedJobSelection, { SavedJobSelectionType } from "../components/SavedJobSelection";
import { BackendPromptType } from "../../profile/models/BackendPrompt";


export const messageCellForCoverLetterChat = (message: Message, coverLetterType?: CoverLetterType): ReactNode => {
    if (!coverLetterType) {
        return (<>
            <MessageCell
                key={0}
                message={message}
                onBookmarkPressed={() => { }}
                onBookmarkJobPressed={() => { }}
                isNewest={true}
                accessory={<><br /><CoverLetTypeSelection /></>}
            />
        </>);
    }
    let accessory: ReactNode | undefined;
    let messageText = '';

    switch (coverLetterType) {
        case CoverLetterType.savedJobs:
            messageText = `Which saved job would you like to create a cover letter for?`;
            accessory = <SavedJobSelection type={SavedJobSelectionType.coverLetter} />;
            break;
        case CoverLetterType.specificRole:
            messageText = `What type of job / role would you like to create a cover letter for?`;
            break;

    }
    return (<>
        <MessageCell
            key={0}
            message={{ ...message, content: messageText }}
            onBookmarkPressed={() => { }}
            onBookmarkJobPressed={() => { }}
            isNewest={true}
            accessory={accessory ? <><br />{accessory}</> : undefined}
            richContent={accessory ? true : false}
        />
    </>);

}

export const titleForCoverLetterType = (type: CoverLetterType): string => {
    switch (type) {
        case CoverLetterType.general:
            return 'General Cover Letter';
        case CoverLetterType.savedJobs:
            return 'Saved Job Cover Letter';
        case CoverLetterType.specificRole:
            return 'Specific Role Cover Letter';
    }
}

export const initialMessageForCoverLetterType = (type: CoverLetterType): string => {
    switch (type) {
        case CoverLetterType.savedJobs:
            return `Create a cover letter for one of my saved jobs.`;
        case CoverLetterType.specificRole:
            return `Create a cover letter for a specific role or position that I'm interested in.`;
        default:
            return '';
    }
}

export const coverLetterStartMessage = (type: CoverLetterType, state: RootState, job?: Job, role?: string): string => {
    const userSkills = state.userState.currentUser?.info?.skills;
    switch (type) {
        case CoverLetterType.general:
            const prompt = state.promptState.backendPrompts![BackendPromptType.coverLetter][CoverLetterType.general];
            return `${prompt.prompt}`;
        case CoverLetterType.savedJobs:
            const prompt1 = state.promptState.backendPrompts![BackendPromptType.coverLetter][CoverLetterType.savedJobs];
            return `${prompt1.prompt.replaceAll('[JOB_TITLE]', job?.title ?? '').replaceAll('[JOB_COMPANY]', job?.company ?? '').replaceAll('[JOB_SUMMARY]', job?.summary ?? '')}`;
        case CoverLetterType.specificRole:
            const prompt2 = state.promptState.backendPrompts![BackendPromptType.coverLetter][CoverLetterType.specificRole];
            return `${prompt2.prompt.replaceAll('[SELECTED_ROLE]', role ?? '')}`;
    }
}
