import styled from "@emotion/styled";
import { Switch, SwitchProps, Typography, styled as muiStyled } from "@mui/material";
import { CSSProperties, Dispatch, SetStateAction, useState } from "react";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import {
    resetCoverLetterPrompt,
    resetJobMatchPrompt,
    resetMockInterviewPrompt,
    resetResumeOptimizePrompt,
    resetUpskillPrompt,
    setCoverLetterPrompt,
    setJobMatchPrompt,
    setMockInterviewPrompt,
    setResumeOptimizePrompt,
    setUpskillPrompt,
    updatePrompt
} from "../redux/reducers/promptReducer";
import ClickableOpacityDiv from "../shared/components/ClickableOpacityDiv";
import PPMultiLineTextInput from "../shared/components/PPMultiLIneTextInput";
import { AppColors } from "../theme/AppTheme";
import AdminSettingRow from "./components/AdminSettingRow";
import ProfileSectionHeader from "./components/ProfileSectionHeader";
import { resetJobSummaryMessage, setJobSummaryMessage } from "../redux/reducers/appReducer";
import PrimaryButton from "../shared/components/PrimaryButton";
import BackendPrompt, { BackendPromptType } from "./models/BackendPrompt";
import JobMatchType from "../shared/enums/JobMatchType";
import TopLevelJobMatchType from "../shared/enums/TopLevelJobMatchType";
import PromptsService from "../services/promptsService";
import ResumeFixType from "../shared/enums/ResumeType";
import CoverLetterType from "../shared/enums/CoverLetterType";
import UpskillingType from "../shared/enums/UpskillingType";
import InterviewQuestionsType from "../shared/enums/InterviewQuestionsType";

const IOSSwitch = muiStyled((props: SwitchProps) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    marginRight: '12px',
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: AppColors.pink.dark,
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: AppColors.grey.darkish,
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

const PromptBox = styled('div')({
    width: '100%',
    border: '1px #DDD solid',
    borderRadius: '12px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    padding: '12px',
});

const PromptSection = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '100%',
    marginBottom: '24px',
    marginTop: '24px',
    gap: '12px',
});

const ScrollingWrapper = styled('div')({
    overflowY: 'auto',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
});

const StyledContainer = styled('div')((props: { style?: CSSProperties, isMobile: boolean }) => ({
    padding: props.isMobile ? '24px' : '50px',
    maxWidth: '1200px',
    width: props.isMobile ? 'calc(100% - 48px)' : '100%',
    ...props.style,
}));

const Divider = () => {
    return <div style={{ marginTop: '12px', width: '100%', minHeight: '1.5px', backgroundColor: AppColors.separator }} />
}

const SectionTitle = (props: { title: string, top?: boolean }) => {
    return <div style={{ marginTop: props.top ? '40px' : '100px' }}>
        <Typography variant="h4" style={{ textAlign: 'left', color: AppColors.link, fontWeight: 500, fontSize: '18px' }}>
            {props.title}
        </Typography>
        <Divider />
    </div>
}

const PromptPage = () => {
    const allPrompts = useAppSelector(store => store.promptState.backendPrompts);

    const [jobPrompt1, setJobPrompt1] = useState(allPrompts![BackendPromptType.job][JobMatchType.resumeAndPreferences]);
    const [jobPrompt2, setJobPrompt2] = useState(allPrompts![BackendPromptType.job][JobMatchType.specificRole]);
    const [jobPrompt3, setJobPrompt3] = useState(allPrompts![BackendPromptType.job][TopLevelJobMatchType.advice]);

    const [resumePrompt1, setResumePrompt1] = useState(allPrompts![BackendPromptType.resume][ResumeFixType.improvements]);
    const [resumePrompt2, setResumePrompt2] = useState(allPrompts![BackendPromptType.resume][ResumeFixType.bestPractices]);
    const [resumePrompt3, setResumePrompt3] = useState(allPrompts![BackendPromptType.resume][ResumeFixType.savedJobs]);
    const [resumePrompt4, setResumePrompt4] = useState(allPrompts![BackendPromptType.resume][ResumeFixType.specificRole]);

    const [coverLetterPrompt1, setCoverLetterPrompt1] = useState(allPrompts![BackendPromptType.coverLetter][CoverLetterType.general]);
    const [coverLetterPrompt2, setCoverLetterPrompt2] = useState(allPrompts![BackendPromptType.coverLetter][CoverLetterType.savedJobs]);
    const [coverLetterPrompt3, setCoverLetterPrompt3] = useState(allPrompts![BackendPromptType.coverLetter][CoverLetterType.specificRole]);

    const [upskillPrompt1, setUpskillPrompt1] = useState(allPrompts![BackendPromptType.upskill][UpskillingType.resumeAndPreferences]);
    const [upskillPrompt2, setUpskillPrompt2] = useState(allPrompts![BackendPromptType.upskill][UpskillingType.savedJobs]);
    const [upskillPrompt3, setUpskillPrompt3] = useState(allPrompts![BackendPromptType.upskill][UpskillingType.specificRole]);

    const [interviewPrompt1, setInterviewPrompt1] = useState(allPrompts![BackendPromptType.interviewPrep][InterviewQuestionsType.resume]);
    const [interviewPrompt2, setInterviewPrompt2] = useState(allPrompts![BackendPromptType.interviewPrep][InterviewQuestionsType.job]);
    const [interviewPrompt3, setInterviewPrompt3] = useState(allPrompts![BackendPromptType.interviewPrep][InterviewQuestionsType.specified]);

    const isMobile = useAppSelector(store => store.appState.isMobile);
    const dispatch = useAppDispatch();

    const savePrompt = async (newPrompt: BackendPrompt) => {
        await PromptsService.updatePrompt(newPrompt.id, newPrompt.prompt);
        dispatch(updatePrompt({ promptId: newPrompt.id, newPrompt: newPrompt.prompt }));
    }

    const resetPrompt = async (prompt: BackendPrompt, localDispatch: Dispatch<SetStateAction<BackendPrompt>>) => {
        const newPrompt = await PromptsService.resetPrompt(prompt);
        if (!!newPrompt) {
            dispatch(updatePrompt({ promptId: newPrompt.id, newPrompt: newPrompt.prompt }));
            localDispatch(newPrompt);
        }
    }

    return (
        <ScrollingWrapper>
            <StyledContainer isMobile={isMobile}>
                <ProfileSectionHeader title='Prompt Settings' />

                {/* ---------------- Job Prompts ---------------- */}
                <SectionTitle title='Job Prompts' />

                <PromptSection>
                    <Typography variant='h3' style={{ color: AppColors.black, marginBottom: '12px' }}>Enable Job Prompts</Typography>

                    {/* Prompt 1 */}
                    <PromptBox>
                        <Typography variant='body1' style={{ color: AppColors.black, fontWeight: 500 }}>
                            Button Message
                        </Typography>
                        <PPMultiLineTextInput
                            disabled
                            id={'jm-1'}
                            value={'Provide me with a list of job matches based on my <b>resume and job preferences</b>'}
                            onChange={(newMessage) => dispatch(setJobMatchPrompt(newMessage))}
                            maxRows={12}
                            style={{
                                border: '1px #EEE solid',
                                borderRadius: '24px',
                                padding: '10px 12px',
                                width: '100%'
                            }}
                        />

                        <Typography variant='body1' style={{ color: AppColors.black, fontWeight: 500 }}>Prompt</Typography>
                        <PPMultiLineTextInput
                            id={'jm-1'}
                            value={jobPrompt1.prompt}
                            onChange={(newMessage) => setJobPrompt1({ ...jobPrompt1, prompt: newMessage })}
                            maxRows={12}
                            style={{
                                border: '1px #EEE solid',
                                borderRadius: '24px',
                                marginBottom: '8px',
                                padding: '10px 12px',
                                width: '100%'
                            }}
                        />
                        <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '12px', width: '100%' }}>
                            <PrimaryButton
                                title={'Save'}
                                disabled={false}
                                onClick={() => savePrompt(jobPrompt1)}
                                height={'44px'}
                                style={{ width: '90px' }}
                            />
                            <PrimaryButton
                                title={'Reset'}
                                backgroundColor={AppColors.grey.dark}
                                disabled={false}
                                onClick={() => resetPrompt(jobPrompt1, setJobPrompt1)}
                                height={'44px'}
                                style={{ width: '90px' }}
                            />
                        </div>
                    </PromptBox>

                    {/* Prompt 2 */}
                    <PromptBox>
                        <Typography variant='body1' style={{ color: AppColors.black, fontWeight: 500 }}>
                            Button Message
                        </Typography>
                        <PPMultiLineTextInput
                            id={'jm-2'}
                            value={'Provide me with a list of jobs for a <b>specific role or position</b>'}
                            onChange={(newMessage) => dispatch(setJobMatchPrompt(newMessage))}
                            maxRows={12}
                            style={{
                                border: '1px #EEE solid',
                                borderRadius: '24px',
                                padding: '10px 12px',
                                width: '100%'
                            }}
                        />

                        <Typography variant='body1' style={{ color: AppColors.black, fontWeight: 500 }}>Prompt</Typography>
                        <PPMultiLineTextInput
                            id={'jm-1'}
                            value={jobPrompt2.prompt}
                            onChange={(newMessage) => setJobPrompt2({ ...jobPrompt2, prompt: newMessage })}
                            maxRows={12}
                            style={{
                                border: '1px #EEE solid',
                                borderRadius: '24px',
                                marginBottom: '8px',
                                padding: '10px 12px',
                                width: '100%'
                            }}
                        />
                        <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '12px', width: '100%' }}>
                            <PrimaryButton
                                title={'Save'}
                                disabled={false}
                                onClick={() => savePrompt(jobPrompt2)}
                                height={'44px'}
                                style={{ width: '90px' }}
                            />
                            <PrimaryButton
                                title={'Reset'}
                                backgroundColor={AppColors.grey.dark}
                                disabled={false}
                                onClick={() => resetPrompt(jobPrompt2, setJobPrompt2)}
                                height={'44px'}
                                style={{ width: '90px' }}
                            />
                        </div>
                    </PromptBox>

                    {/* Prompt 3 */}
                    <PromptBox>
                        <Typography variant='body1' style={{ color: AppColors.black, fontWeight: 500 }}>
                            Button Message
                        </Typography>
                        <PPMultiLineTextInput
                            id={'jm-1'}
                            value={'Provide me with <b>advice</b> on the types of jobs I should be looking for based on my skills and experience'}
                            onChange={(newMessage) => dispatch(setJobMatchPrompt(newMessage))}
                            maxRows={12}
                            style={{
                                border: '1px #EEE solid',
                                borderRadius: '24px',
                                padding: '10px 12px',
                                width: '100%'
                            }}
                        />

                        <Typography variant='body1' style={{ color: AppColors.black, fontWeight: 500 }}>Prompt</Typography>
                        <PPMultiLineTextInput
                            id={'jm-1'}
                            value={jobPrompt3.prompt}
                            onChange={(newMessage) => setJobPrompt3({ ...jobPrompt3, prompt: newMessage })}
                            maxRows={12}
                            style={{
                                border: '1px #EEE solid',
                                borderRadius: '24px',
                                marginBottom: '8px',
                                padding: '10px 12px',
                                width: '100%'
                            }}
                        />
                        <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '12px', width: '100%' }}>
                            <PrimaryButton
                                title={'Save'}
                                disabled={false}
                                onClick={() => savePrompt(jobPrompt3)}
                                height={'44px'}
                                style={{ width: '90px' }}
                            />
                            <PrimaryButton
                                title={'Reset'}
                                backgroundColor={AppColors.grey.dark}
                                disabled={false}
                                onClick={() => resetPrompt(jobPrompt3, setJobPrompt3)}
                                height={'44px'}
                                style={{ width: '90px' }}
                            />
                        </div>
                    </PromptBox>
                </PromptSection>

                {/* ---------------- Resume Prompts ---------------- */}
                <SectionTitle title='Resume Prompts' />

                <PromptSection>
                    <Typography variant='h3' style={{ color: AppColors.black, marginBottom: '12px' }}>
                        Resume Optimization Settings
                    </Typography>

                    {/* Prompt 1 */}
                    <PromptBox>
                        <Typography variant='body1' style={{ color: AppColors.black, fontWeight: 500 }}>Button Message</Typography>
                        <PPMultiLineTextInput
                            disabled
                            id={'jm-1'}
                            value={'Conduct an assessment of my resume and give me advice on how to improve it'}
                            onChange={() => { }}
                            maxRows={12}
                            style={{
                                border: '1px #EEE solid',
                                borderRadius: '24px',
                                padding: '10px 12px',
                                width: '100%'
                            }}
                        />

                        <Typography variant='body1' style={{ color: AppColors.black, fontWeight: 500 }}>Prompt</Typography>
                        <PPMultiLineTextInput
                            id={'jm-1'}
                            value={resumePrompt1.prompt}
                            onChange={(newMessage) => setResumePrompt1({ ...resumePrompt1, prompt: newMessage })}
                            maxRows={12}
                            style={{
                                border: '1px #EEE solid',
                                borderRadius: '24px',
                                marginBottom: '8px',
                                padding: '10px 12px',
                                width: '100%'
                            }}
                        />
                        <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '12px', width: '100%' }}>
                            <PrimaryButton
                                title={'Save'}
                                disabled={false}
                                onClick={() => savePrompt(resumePrompt1)}
                                height={'44px'}
                                style={{ width: '90px' }}
                            />
                            <PrimaryButton
                                title={'Reset'}
                                backgroundColor={AppColors.grey.dark}
                                disabled={false}
                                onClick={() => resetPrompt(resumePrompt1, setResumePrompt1)}
                                height={'44px'}
                                style={{ width: '90px' }}
                            />
                        </div>
                    </PromptBox>

                    {/* Prompt 2 */}
                    <PromptBox>
                        <Typography variant='body1' style={{ color: AppColors.black, fontWeight: 500 }}>Button Message</Typography>
                        <PPMultiLineTextInput
                            id={'jm-2'}
                            value={'Optimize my resume based on general resume <b>best practices</b>'}
                            onChange={(newMessage) => dispatch(setJobMatchPrompt(newMessage))}
                            maxRows={12}
                            style={{
                                border: '1px #EEE solid',
                                borderRadius: '24px',
                                padding: '10px 12px',
                                width: '100%'
                            }}
                        />

                        <Typography variant='body1' style={{ color: AppColors.black, fontWeight: 500 }}>Prompt</Typography>
                        <PPMultiLineTextInput
                            id={'jm-1'}
                            value={resumePrompt2.prompt}
                            onChange={(newMessage) => setResumePrompt2({ ...resumePrompt2, prompt: newMessage })}
                            maxRows={12}
                            style={{
                                border: '1px #EEE solid',
                                borderRadius: '24px',
                                marginBottom: '8px',
                                padding: '10px 12px',
                                width: '100%'
                            }}
                        />
                        <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '12px', width: '100%' }}>
                            <PrimaryButton
                                title={'Save'}
                                disabled={false}
                                onClick={() => savePrompt(resumePrompt2)}
                                height={'44px'}
                                style={{ width: '90px' }}
                            />
                            <PrimaryButton
                                title={'Reset'}
                                backgroundColor={AppColors.grey.dark}
                                disabled={false}
                                onClick={() => resetPrompt(resumePrompt2, setResumePrompt2)}
                                height={'44px'}
                                style={{ width: '90px' }}
                            />
                        </div>
                    </PromptBox>

                    {/* Prompt 3 */}
                    <PromptBox>
                        <Typography variant='body1' style={{ color: AppColors.black, fontWeight: 500 }}>Button Message</Typography>
                        <PPMultiLineTextInput
                            id={'jm-1'}
                            value={'Optimize my resume based on one of my <b>saved jobs</b>'}
                            onChange={(newMessage) => dispatch(setJobMatchPrompt(newMessage))}
                            maxRows={12}
                            style={{
                                border: '1px #EEE solid',
                                borderRadius: '24px',
                                padding: '10px 12px',
                                width: '100%'
                            }}
                        />

                        <Typography variant='body1' style={{ color: AppColors.black, fontWeight: 500 }}>Prompt</Typography>
                        <PPMultiLineTextInput
                            id={'jm-1'}
                            value={resumePrompt3.prompt}
                            onChange={(newMessage) => setResumePrompt3({ ...resumePrompt3, prompt: newMessage })}
                            maxRows={12}
                            style={{
                                border: '1px #EEE solid',
                                borderRadius: '24px',
                                marginBottom: '8px',
                                padding: '10px 12px',
                                width: '100%'
                            }}
                        />
                        <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '12px', width: '100%' }}>
                            <PrimaryButton
                                title={'Save'}
                                disabled={false}
                                onClick={() => savePrompt(resumePrompt3)}
                                height={'44px'}
                                style={{ width: '90px' }}
                            />
                            <PrimaryButton
                                title={'Reset'}
                                backgroundColor={AppColors.grey.dark}
                                disabled={false}
                                onClick={() => resetPrompt(resumePrompt3, setResumePrompt3)}
                                height={'44px'}
                                style={{ width: '90px' }}
                            />
                        </div>
                    </PromptBox>

                    {/* Prompt 4 */}
                    <PromptBox>
                        <Typography variant='body1' style={{ color: AppColors.black, fontWeight: 500 }}>Button Message</Typography>
                        <PPMultiLineTextInput
                            id={'jm-1'}
                            value={'Optimize my resume for a <b>specific role or position</b> that I\'m interested in'}
                            onChange={(newMessage) => dispatch(setJobMatchPrompt(newMessage))}
                            maxRows={12}
                            style={{
                                border: '1px #EEE solid',
                                borderRadius: '24px',
                                padding: '10px 12px',
                                width: '100%'
                            }}
                        />

                        <Typography variant='body1' style={{ color: AppColors.black, fontWeight: 500 }}>Prompt</Typography>
                        <PPMultiLineTextInput
                            id={'jm-1'}
                            value={resumePrompt4.prompt}
                            onChange={(newMessage) => setResumePrompt4({ ...resumePrompt4, prompt: newMessage })}
                            maxRows={12}
                            style={{
                                border: '1px #EEE solid',
                                borderRadius: '24px',
                                marginBottom: '8px',
                                padding: '10px 12px',
                                width: '100%'
                            }}
                        />
                        <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '12px', width: '100%' }}>
                            <PrimaryButton
                                title={'Save'}
                                disabled={false}
                                onClick={() => savePrompt(resumePrompt4)}
                                height={'44px'}
                                style={{ width: '90px' }}
                            />
                            <PrimaryButton
                                title={'Reset'}
                                backgroundColor={AppColors.grey.dark}
                                disabled={false}
                                onClick={() => resetPrompt(resumePrompt4, setResumePrompt4)}
                                height={'44px'}
                                style={{ width: '90px' }}
                            />
                        </div>
                    </PromptBox>
                </PromptSection>

                {/* ---------------- Cover Letter Prompts ---------------- */}
                <SectionTitle title='Cover Letter Prompts' />

                <PromptSection>
                    <Typography variant='h3' style={{ color: AppColors.black, marginBottom: '12px' }}>
                        Cover Letter Prompt Settings
                    </Typography>

                    {/* Prompt 1 (General) */}
                    <PromptBox>
                        <Typography variant='body1' style={{ color: AppColors.black, fontWeight: 500 }}>Button Message</Typography>
                        <PPMultiLineTextInput
                            disabled
                            id={'cl-1'}
                            value={'Create a <b>general cover letter</b> based on my skills and experience'}
                            onChange={() => { }}
                            maxRows={12}
                            style={{
                                border: '1px #EEE solid',
                                borderRadius: '24px',
                                padding: '10px 12px',
                                width: '100%'
                            }}
                        />
                        <Typography variant='body1' style={{ color: AppColors.black, fontWeight: 500 }}>Prompt</Typography>
                        <PPMultiLineTextInput
                            id={'cl-1'}
                            value={coverLetterPrompt1.prompt}
                            onChange={(newMessage) => setCoverLetterPrompt1({ ...coverLetterPrompt1, prompt: newMessage })}
                            maxRows={12}
                            style={{
                                border: '1px #EEE solid',
                                borderRadius: '24px',
                                marginBottom: '8px',
                                padding: '10px 12px',
                                width: '100%'
                            }}
                        />
                        <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '12px', width: '100%' }}>
                            <PrimaryButton
                                title={'Save'}
                                disabled={false}
                                onClick={() => savePrompt(coverLetterPrompt1)}
                                height={'44px'}
                                style={{ width: '90px' }}
                            />
                            <PrimaryButton
                                title={'Reset'}
                                backgroundColor={AppColors.grey.dark}
                                disabled={false}
                                onClick={() => resetPrompt(coverLetterPrompt1, setCoverLetterPrompt1)}
                                height={'44px'}
                                style={{ width: '90px' }}
                            />
                        </div>
                    </PromptBox>

                    {/* Prompt 2 (Saved Jobs) */}
                    <PromptBox>
                        <Typography variant='body1' style={{ color: AppColors.black, fontWeight: 500 }}>Button Message</Typography>
                        <PPMultiLineTextInput
                            id={'cl-2'}
                            value={'Create a cover letter for one of my <b>saved jobs</b>'}
                            onChange={() => { }}
                            maxRows={12}
                            style={{
                                border: '1px #EEE solid',
                                borderRadius: '24px',
                                padding: '10px 12px',
                                width: '100%'
                            }}
                        />
                        <Typography variant='body1' style={{ color: AppColors.black, fontWeight: 500 }}>Prompt</Typography>
                        <PPMultiLineTextInput
                            id={'cl-2'}
                            value={coverLetterPrompt2.prompt}
                            onChange={(newMessage) => setCoverLetterPrompt2({ ...coverLetterPrompt2, prompt: newMessage })}
                            maxRows={12}
                            style={{
                                border: '1px #EEE solid',
                                borderRadius: '24px',
                                marginBottom: '8px',
                                padding: '10px 12px',
                                width: '100%'
                            }}
                        />
                        <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '12px', width: '100%' }}>
                            <PrimaryButton
                                title={'Save'}
                                disabled={false}
                                onClick={() => savePrompt(coverLetterPrompt2)}
                                height={'44px'}
                                style={{ width: '90px' }}
                            />
                            <PrimaryButton
                                title={'Reset'}
                                backgroundColor={AppColors.grey.dark}
                                disabled={false}
                                onClick={() => resetPrompt(coverLetterPrompt2, setCoverLetterPrompt2)}
                                height={'44px'}
                                style={{ width: '90px' }}
                            />
                        </div>
                    </PromptBox>

                    {/* Prompt 3 (Specific Role) */}
                    <PromptBox>
                        <Typography variant='body1' style={{ color: AppColors.black, fontWeight: 500 }}>Button Message</Typography>
                        <PPMultiLineTextInput
                            id={'cl-3'}
                            value={'Create a cover letter for a <b>specific role or position</b> that I\'m interested in'}
                            onChange={() => { }}
                            maxRows={12}
                            style={{
                                border: '1px #EEE solid',
                                borderRadius: '24px',
                                padding: '10px 12px',
                                width: '100%'
                            }}
                        />
                        <Typography variant='body1' style={{ color: AppColors.black, fontWeight: 500 }}>Prompt</Typography>
                        <PPMultiLineTextInput
                            id={'cl-3'}
                            value={coverLetterPrompt3.prompt}
                            onChange={(newMessage) => setCoverLetterPrompt3({ ...coverLetterPrompt3, prompt: newMessage })}
                            maxRows={12}
                            style={{
                                border: '1px #EEE solid',
                                borderRadius: '24px',
                                marginBottom: '8px',
                                padding: '10px 12px',
                                width: '100%'
                            }}
                        />
                        <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '12px', width: '100%' }}>
                            <PrimaryButton
                                title={'Save'}
                                disabled={false}
                                onClick={() => savePrompt(coverLetterPrompt3)}
                                height={'44px'}
                                style={{ width: '90px' }}
                            />
                            <PrimaryButton
                                title={'Reset'}
                                backgroundColor={AppColors.grey.dark}
                                disabled={false}
                                onClick={() => resetPrompt(coverLetterPrompt3, setCoverLetterPrompt3)}
                                height={'44px'}
                                style={{ width: '90px' }}
                            />
                        </div>
                    </PromptBox>
                </PromptSection>

                {/* ---------------- Upskilling Prompts ---------------- */}
                <SectionTitle title='Upskilling Prompts' />
                <PromptSection>
                    <Typography variant='h3' style={{ color: AppColors.black, marginBottom: '12px' }}>
                        Upskilling Prompt Settings
                    </Typography>

                    {/* Prompt 1 (Resume & Preferences) */}
                    <PromptBox>
                        <Typography variant='body1' style={{ color: AppColors.black, fontWeight: 500 }}>Button Message</Typography>
                        <PPMultiLineTextInput
                            disabled
                            id={'upskill-1'}
                            value={'Give me some upskilling suggestions based on my <b>resume and job preferences</b>'}
                            onChange={() => { }}
                            maxRows={12}
                            style={{
                                border: '1px #EEE solid',
                                borderRadius: '24px',
                                padding: '10px 12px',
                                width: '100%'
                            }}
                        />
                        <Typography variant='body1' style={{ color: AppColors.black, fontWeight: 500 }}>Prompt</Typography>
                        <PPMultiLineTextInput
                            id={'upskill-1'}
                            value={upskillPrompt1.prompt}
                            onChange={(newMessage) => setUpskillPrompt1({ ...upskillPrompt1, prompt: newMessage })}
                            maxRows={12}
                            style={{
                                border: '1px #EEE solid',
                                borderRadius: '24px',
                                marginBottom: '8px',
                                padding: '10px 12px',
                                width: '100%'
                            }}
                        />
                        <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '12px', width: '100%' }}>
                            <PrimaryButton
                                title={'Save'}
                                disabled={false}
                                onClick={() => savePrompt(upskillPrompt1)}
                                height={'44px'}
                                style={{ width: '90px' }}
                            />
                            <PrimaryButton
                                title={'Reset'}
                                backgroundColor={AppColors.grey.dark}
                                disabled={false}
                                onClick={() => resetPrompt(upskillPrompt1, setUpskillPrompt1)}
                                height={'44px'}
                                style={{ width: '90px' }}
                            />
                        </div>
                    </PromptBox>

                    {/* Prompt 2 (Saved Jobs) */}
                    <PromptBox>
                        <Typography variant='body1' style={{ color: AppColors.black, fontWeight: 500 }}>Button Message</Typography>
                        <PPMultiLineTextInput
                            id={'upskill-2'}
                            value={'Give me some upskilling suggestions based on one of my <b>saved jobs</b>'}
                            onChange={() => { }}
                            maxRows={12}
                            style={{
                                border: '1px #EEE solid',
                                borderRadius: '24px',
                                padding: '10px 12px',
                                width: '100%'
                            }}
                        />
                        <Typography variant='body1' style={{ color: AppColors.black, fontWeight: 500 }}>Prompt</Typography>
                        <PPMultiLineTextInput
                            id={'upskill-2'}
                            value={upskillPrompt2.prompt}
                            onChange={(newMessage) => setUpskillPrompt2({ ...upskillPrompt2, prompt: newMessage })}
                            maxRows={12}
                            style={{
                                border: '1px #EEE solid',
                                borderRadius: '24px',
                                marginBottom: '8px',
                                padding: '10px 12px',
                                width: '100%'
                            }}
                        />
                        <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '12px', width: '100%' }}>
                            <PrimaryButton
                                title={'Save'}
                                disabled={false}
                                onClick={() => savePrompt(upskillPrompt2)}
                                height={'44px'}
                                style={{ width: '90px' }}
                            />
                            <PrimaryButton
                                title={'Reset'}
                                backgroundColor={AppColors.grey.dark}
                                disabled={false}
                                onClick={() => resetPrompt(upskillPrompt2, setUpskillPrompt2)}
                                height={'44px'}
                                style={{ width: '90px' }}
                            />
                        </div>
                    </PromptBox>

                    {/* Prompt 3 (Specific Role) */}
                    <PromptBox>
                        <Typography variant='body1' style={{ color: AppColors.black, fontWeight: 500 }}>Button Message</Typography>
                        <PPMultiLineTextInput
                            id={'upskill-3'}
                            value={'Give me some upskilling suggestions based on a <b>specific job / role</b> I\'m interested in'}
                            onChange={() => { }}
                            maxRows={12}
                            style={{
                                border: '1px #EEE solid',
                                borderRadius: '24px',
                                padding: '10px 12px',
                                width: '100%'
                            }}
                        />
                        <Typography variant='body1' style={{ color: AppColors.black, fontWeight: 500 }}>Prompt</Typography>
                        <PPMultiLineTextInput
                            id={'upskill-3'}
                            value={upskillPrompt3.prompt}
                            onChange={(newMessage) => setUpskillPrompt3({ ...upskillPrompt3, prompt: newMessage })}
                            maxRows={12}
                            style={{
                                border: '1px #EEE solid',
                                borderRadius: '24px',
                                marginBottom: '8px',
                                padding: '10px 12px',
                                width: '100%'
                            }}
                        />
                        <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '12px', width: '100%' }}>
                            <PrimaryButton
                                title={'Save'}
                                disabled={false}
                                onClick={() => savePrompt(upskillPrompt3)}
                                height={'44px'}
                                style={{ width: '90px' }}
                            />
                            <PrimaryButton
                                title={'Reset'}
                                backgroundColor={AppColors.grey.dark}
                                disabled={false}
                                onClick={() => resetPrompt(upskillPrompt3, setUpskillPrompt3)}
                                height={'44px'}
                                style={{ width: '90px' }}
                            />
                        </div>
                    </PromptBox>
                </PromptSection>

                {/* ---------------- Interview Prompts ---------------- */}
                <SectionTitle title='Interview Prep Prompts' />
                <PromptSection>
                    <Typography variant='h3' style={{ color: AppColors.black, marginBottom: '12px' }}>
                        Interview Preparation Prompt Settings
                    </Typography>

                    {/* Prompt 1 (Resume) */}
                    <PromptBox>
                        <Typography variant='body1' style={{ color: AppColors.black, fontWeight: 500 }}>Button Message</Typography>
                        <PPMultiLineTextInput
                            disabled
                            id={'int-1'}
                            value={'I would like to prepare for a general interview based on my <b>current resume</b>'}
                            onChange={() => { }}
                            maxRows={12}
                            style={{
                                border: '1px #EEE solid',
                                borderRadius: '24px',
                                padding: '10px 12px',
                                width: '100%'
                            }}
                        />
                        <Typography variant='body1' style={{ color: AppColors.black, fontWeight: 500 }}>Prompt</Typography>
                        <PPMultiLineTextInput
                            id={'int-1'}
                            value={interviewPrompt1.prompt}
                            onChange={(newMessage) => setInterviewPrompt1({ ...interviewPrompt1, prompt: newMessage })}
                            maxRows={12}
                            style={{
                                border: '1px #EEE solid',
                                borderRadius: '24px',
                                marginBottom: '8px',
                                padding: '10px 12px',
                                width: '100%'
                            }}
                        />
                        <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '12px', width: '100%' }}>
                            <PrimaryButton
                                title={'Save'}
                                disabled={false}
                                onClick={() => savePrompt(interviewPrompt1)}
                                height={'44px'}
                                style={{ width: '90px' }}
                            />
                            <PrimaryButton
                                title={'Reset'}
                                backgroundColor={AppColors.grey.dark}
                                disabled={false}
                                onClick={() => resetPrompt(interviewPrompt1, setInterviewPrompt1)}
                                height={'44px'}
                                style={{ width: '90px' }}
                            />
                        </div>
                    </PromptBox>

                    {/* Prompt 2 (Saved Job) */}
                    <PromptBox>
                        <Typography variant='body1' style={{ color: AppColors.black, fontWeight: 500 }}>Button Message</Typography>
                        <PPMultiLineTextInput
                            id={'int-2'}
                            value={'I would like to prepare for an interview for one of my <b>saved jobs</b>'}
                            onChange={() => { }}
                            maxRows={12}
                            style={{
                                border: '1px #EEE solid',
                                borderRadius: '24px',
                                padding: '10px 12px',
                                width: '100%'
                            }}
                        />
                        <Typography variant='body1' style={{ color: AppColors.black, fontWeight: 500 }}>Prompt</Typography>
                        <PPMultiLineTextInput
                            id={'int-2'}
                            value={interviewPrompt2.prompt}
                            onChange={(newMessage) => setInterviewPrompt2({ ...interviewPrompt2, prompt: newMessage })}
                            maxRows={12}
                            style={{
                                border: '1px #EEE solid',
                                borderRadius: '24px',
                                marginBottom: '8px',
                                padding: '10px 12px',
                                width: '100%'
                            }}
                        />
                        <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '12px', width: '100%' }}>
                            <PrimaryButton
                                title={'Save'}
                                disabled={false}
                                onClick={() => savePrompt(interviewPrompt2)}
                                height={'44px'}
                                style={{ width: '90px' }}
                            />
                            <PrimaryButton
                                title={'Reset'}
                                backgroundColor={AppColors.grey.dark}
                                disabled={false}
                                onClick={() => resetPrompt(interviewPrompt2, setInterviewPrompt2)}
                                height={'44px'}
                                style={{ width: '90px' }}
                            />
                        </div>
                    </PromptBox>

                    {/* Prompt 3 (Specified Role) */}
                    <PromptBox>
                        <Typography variant='body1' style={{ color: AppColors.black, fontWeight: 500 }}>Button Message</Typography>
                        <PPMultiLineTextInput
                            id={'int-3'}
                            value={'I would like to prepare for an interview for a <b>specific type of job / role</b> I\'m interested in'}
                            onChange={() => { }}
                            maxRows={12}
                            style={{
                                border: '1px #EEE solid',
                                borderRadius: '24px',
                                padding: '10px 12px',
                                width: '100%'
                            }}
                        />
                        <Typography variant='body1' style={{ color: AppColors.black, fontWeight: 500 }}>Prompt</Typography>
                        <PPMultiLineTextInput
                            id={'int-3'}
                            value={interviewPrompt3.prompt}
                            onChange={(newMessage) => setInterviewPrompt3({ ...interviewPrompt3, prompt: newMessage })}
                            maxRows={12}
                            style={{
                                border: '1px #EEE solid',
                                borderRadius: '24px',
                                marginBottom: '8px',
                                padding: '10px 12px',
                                width: '100%'
                            }}
                        />
                        <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '12px', width: '100%' }}>
                            <PrimaryButton
                                title={'Save'}
                                disabled={false}
                                onClick={() => savePrompt(interviewPrompt3)}
                                height={'44px'}
                                style={{ width: '90px' }}
                            />
                            <PrimaryButton
                                title={'Reset'}
                                backgroundColor={AppColors.grey.dark}
                                disabled={false}
                                onClick={() => resetPrompt(interviewPrompt3, setInterviewPrompt3)}
                                height={'44px'}
                                style={{ width: '90px' }}
                            />
                        </div>
                    </PromptBox>
                </PromptSection>

                <div style={{ minHeight: '20px', maxHeight: '20px' }} />
            </StyledContainer>
        </ScrollingWrapper>
    )
}

export default PromptPage;