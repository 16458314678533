import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { MultiFactorResolver, User } from 'firebase/auth';
import { auth } from '../../firebaseConfigs';
import SavedChat from '../../shared/models/SavedChat';
import PPUser from '../../shared/models/User';
import JobDescription from '../../shared/models/JobDescription';
import { AuthType, OnboardingOptions } from '../../auth/AuthFlowContainer';

interface UserState {
    initializing: boolean;
    currentUser?: PPUser;
    authIsBusy: boolean;
    authError?: string;
    onboarding: boolean;
    onboardingType: AuthType;
    twoFactorOpen: boolean;
    twoFactorResolver?: MultiFactorResolver;
    savedChatsToggle: boolean;
    jobDescriptionToggle: boolean;
    emailVerifcationSent: boolean;
    onTwoFactorSuccess?: () => void;
    onTwoFactorFail?: () => void;
    fbUser: User | null;
}

const initialState: UserState = {
    authIsBusy: false,
    initializing: true,
    onboarding: false,
    onboardingType: AuthType.signUp,
    twoFactorOpen: false,
    savedChatsToggle: false,
    jobDescriptionToggle: false,
    emailVerifcationSent: false,
    fbUser: auth.currentUser,
};

interface Set2FAPayload {
    resolver: MultiFactorResolver;
    onSuccess?: () => void;
    onFail?: () => void;
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<PPUser>) => {
            state.currentUser = action.payload;
        },
        clearUser: (state) => {
            state.currentUser = undefined;
        },
        setAuthIsBusy: (state, action: PayloadAction<boolean>) => {
            state.authIsBusy = action.payload;
        },
        setAuthError: (state, action: PayloadAction<string>) => {
            state.authError = action.payload;
        },
        clearAuthError: (state) => {
            state.authError = undefined;
        },
        setOnboarding: (state, action: PayloadAction<OnboardingOptions>) => {
            state.onboarding = action.payload.open;
            state.onboardingType = action.payload.type ?? AuthType.signUp;
        },
        setTwoFactorOpen: (state, action: PayloadAction<boolean>) => {
            state.twoFactorResolver = undefined;
            state.onTwoFactorSuccess = undefined;
            state.onTwoFactorFail = undefined;
            state.twoFactorOpen = action.payload;
        },
        doneInitializing: (state) => {
            state.initializing = false;
        },
        updateSavedChat: (state, action: PayloadAction<SavedChat>) => {
            let currentUser = state.currentUser;
            if (!currentUser) return;
            currentUser.savedChats = currentUser.savedChats.map(chat => chat.id !== action.payload.id ? chat : action.payload);
            state.currentUser = currentUser;
            state.savedChatsToggle = !state.savedChatsToggle;
        },
        addSavedChat: (state, action: PayloadAction<SavedChat>) => {
            state.currentUser?.savedChats.unshift(action.payload);
            state.savedChatsToggle = !state.savedChatsToggle;
        },
        removeSavedChat: (state, action: PayloadAction<SavedChat>) => {
            let user = state.currentUser;
            if (!user) return;
            const currentChats = user.savedChats;
            const filteredChats = currentChats.filter(chat => chat.id !== action.payload.id);
            user.savedChats = filteredChats;
            state.currentUser = user;
            state.savedChatsToggle = !state.savedChatsToggle;
        },
        addJobDescription: (state, action: PayloadAction<JobDescription>) => {
            state.currentUser?.jobDescriptions.push(action.payload);
            state.jobDescriptionToggle = !state.jobDescriptionToggle;
        },
        setEmailVerificationSent: (state, action: PayloadAction<boolean>) => {
            state.emailVerifcationSent = action.payload;
        },
        set2FALoginInfo: (state, action: PayloadAction<Set2FAPayload>) => {
            state.twoFactorResolver = action.payload.resolver;
            state.onTwoFactorSuccess = action.payload.onSuccess;
            state.onTwoFactorFail = action.payload.onFail;
            state.twoFactorOpen = true;
        },
        setFirebaseUser: (state, action: PayloadAction<User | null>) => {
            state.fbUser = action.payload;
        },
    },
})

export const {
    setUser,
    clearUser,
    setAuthIsBusy,
    setAuthError,
    clearAuthError,
    setOnboarding,
    setTwoFactorOpen,
    set2FALoginInfo,
    doneInitializing,
    updateSavedChat,
    addSavedChat,
    removeSavedChat,
    setEmailVerificationSent,
    setFirebaseUser,
    addJobDescription,
} = userSlice.actions

export default userSlice.reducer
