import UserInfoDto from "../../profile/dtos/UserInfoDto";
import DesiredIncome from "../../profile/models/DesiredIncome";
import UserInfo from "../../profile/models/UserInfo";
import IResume from "../interfaces/IResume";
import IUser from "../interfaces/IUser";
import JobDescription from "./JobDescription";
import Resource from "./Resource";
import SavedChat from "./SavedChat";
import ViewedJob from "./ViewedJob";

export default class User {
    firstName?: string;
    lastName?: string;
    email: string;
    avatarUrl?: string;
    address?: string;
    phone?: string;
    resume?: IResume;
    info?: UserInfo;
    savedResources: Resource[] = [];
    savedChats: SavedChat[] = [];
    viewedJobs: ViewedJob[] = [];
    jobDescriptions: JobDescription[] = [];
    marketing: boolean;
    onboarded: boolean;
    admin: boolean;

    constructor(props: IUser) {
        const { firstName, lastName, email, avatarUrl, address, phone, resume, info, savedResources, savedChats, viewedJobs, jobDescriptions, marketing, onboarded, admin } = props;
        this.firstName = firstName;
        this.lastName = lastName;
        this.email = email;
        this.avatarUrl = avatarUrl;
        this.address = address;
        this.phone = phone;
        this.resume = resume;
        this.info = infoFromDto(info);
        this.savedResources = savedResources ?? [];
        this.savedChats = savedChats ?? [];
        this.viewedJobs = viewedJobs ?? [];
        this.jobDescriptions = jobDescriptions ?? [];
        this.marketing = marketing;
        this.onboarded = onboarded;
        this.admin = admin ?? false;

        // Handle legacy info
        if ((this.info?.skills?.length ?? 0) > 0) {
            const firstSkill = this.info!.skills![0];
            if (firstSkill.includes(',')) {
                this.info!.skills = firstSkill.split(',').map(skill => skill.trim());
            }
        }
    }

    get initials(): string {
        const firstInitial = !!this.firstName ? this.firstName[0].toUpperCase() : '';
        const lastInitial = !!this.lastName ? this.lastName[0].toUpperCase() : '';
        return `${firstInitial}${lastInitial}`;
    }

    get name(): string {
        return `${this.firstName}${this.lastName ? ` ${this.lastName}` : ''}`;
    }

    serialize() {
        return {
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            avatarUrl: this.avatarUrl,
            address: this.address,
            phone: this.phone,
            marketing: this.marketing,
            onboarded: this.onboarded,
            admin: this.admin,
        };
    }
}

const infoFromDto = (dto?: UserInfoDto): UserInfo | undefined => {
    if (!dto) return;
    return {
        desiredPositions: dto.desiredPositions,
        desiredIncome: dto.desiredIncome ? new DesiredIncome(dto.desiredIncome) : undefined,
        preferredWorkEnvironments: dto.preferredWorkEnvironments,
        preferredLocations: dto.preferredLocations,
        skills: dto.skills
    }
}
