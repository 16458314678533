import SkillsArray from "../../mainTabs/profile/SkillsArray";
import JobInfoRow from "../components/JobInfoRow";

interface JobSummaryResponse {
    "Fit Summary": {
        "Role Fit": string;
    };
    "Overall Fit Assessment": string;
    "Detailed Fit Assessment": {
        emoji: string;
        category: string;
        explanation: string;
    }[];
    "Job Summary": string;
    // "Company Overview": string;
}

export const JobSummaryWidget = (js: JobSummaryResponse, skills: string[]) => {
    return (
        <>
            <JobInfoRow title="Job Summary" subtitle={js["Job Summary"]} />
            <JobInfoRow title={'Skills'} subtitle={<div style={{ marginTop: '7px' }}><SkillsArray emptyStateText="" skills={skills} title='' allSelected={false} /></div>} hideTopBorder />
            <JobInfoRow title="Fit Score" subtitle={js["Fit Summary"]["Role Fit"]} />
            <JobInfoRow title="Fit Assessment" alwaysExpanded={true} subtitle={
                <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: '0px', marginTop: '6px' }}>
                    {js["Overall Fit Assessment"]}
                    <ul style={{
                        display: 'flex', flexDirection: 'column', paddingLeft: '0px', marginTop: '24px'
                    }}>
                        {js["Detailed Fit Assessment"].map((d, index) => (
                            <li key={index} style={{ paddingBottom: '12px' }}>
                                {d.emoji} <strong>{d.category}</strong>: {d.explanation}
                            </li>
                        ))}
                    </ul>
                </div>
            } />
            {/* <JobInfoRow title="Company Overview" subtitle={js["Company Overview"]} /> */}
        </>
    );
}

export default JobSummaryResponse;