import { coverLetterStartMessage, initialMessageForCoverLetterType, titleForCoverLetterType } from "../../chat/utils/CoverLetterHelper";
import CoverLetterType from "../../shared/enums/CoverLetterType";
import PromptType from "../../shared/enums/PromptType";
import Chat, { newCoverLetterChat } from "../../shared/models/Chat";
import Job from "../../shared/models/Job";
import Message, { MessageType } from "../../shared/models/Message";
import { focusText, setActiveChat, toggleFollowChat } from "../reducers/appReducer";
import { AppDispatch, RootState } from "../store";
import { sendMessageAction } from "./ChatActions";

export const coverLetterPromptMessage = 'What kind of cover letter would you like to create?';

export const StartCoverLetterFlowAction = () => {
    return async (dispatch: AppDispatch) => {
        try {
            const message: Message = {
                content: coverLetterPromptMessage,
                dt: (new Date()).toISOString(),
                type: MessageType.received,
            }
            const newChat = newCoverLetterChat([message], `Create a Cover Letter`);
            dispatch(setActiveChat(newChat));
        } catch (error) {
            console.error('Error starting cover letter chat:', error);
        }
    };
}

export const SetCoverLetterTypeAction = (type: CoverLetterType) => {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        try {
            const chat = getState().appState.activeChat;

            if (!chat) return;
            if (type == CoverLetterType.general) {
                dispatch(StartCoverLetterAction(type));
                dispatch(toggleFollowChat());
                return;
            }

            // Recreate the chat with the new cover letter type
            const selectedMessage: Message = {
                content: initialMessageForCoverLetterType(type),
                dt: (new Date()).toISOString(),
                type: MessageType.sent
            }
            const message: Message = {
                content: '',
                dt: (new Date()).toISOString(),
                type: MessageType.received,
            }

            const newChat = newCoverLetterChat([selectedMessage, message], titleForCoverLetterType(type), type);
            dispatch(setActiveChat({ ...newChat, sessionTitle: titleForCoverLetterType(type) }));
            if (type === CoverLetterType.specificRole) {
                dispatch(focusText());
                dispatch(toggleFollowChat());
            }
        } catch (error) {
            console.error('Error setting cover letter type:', error);
        }
    };
}

export const StartCoverLetterAction = (type: CoverLetterType, job?: Job, role?: string) => {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        try {
            const state = getState();
            const chat = state.appState.activeChat;
            if (!chat || chat.isCoverLetterChat === false) return;
            const newChat: Chat = { ...chat, coverLetterGenerated: true };
            dispatch(setActiveChat(newChat));
            dispatch(sendMessageAction(coverLetterStartMessage(type, state, job, role), true, false, { promptType: PromptType.coverLetter }));
        } catch (error) {
            console.error('Error starting cover letter chat:', error);
        }
    };
}