import { auth } from "../firebaseConfigs";

class FeedbackService {
    static submitFeedback = async (feedback: string): Promise<boolean> => {
        try {
            const token = await auth.currentUser?.getIdToken();

            const res = await fetch(`${process.env.REACT_APP_API_URL}/data/feedback`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ feedback }),
            });

            if (!res.ok) {
                console.error('Error submitting feedback:', res.statusText);
                return false;
            }

            return true;
        } catch (error) {
            console.error('Error submitting feedback:', error);
            return false;
        }
    };
}

export default FeedbackService;
