
import JobsService from '../../services/jobsService';
import ModelsService, { AllModels, ModelWithType } from '../../services/modelsService';
import Job from '../../shared/models/Job';
import { setAllModels } from '../reducers/promptReducer';
import { setSavedJobBuckets, setSavedJobs } from '../reducers/savedJobsReducer';
import { AppDispatch, RootState } from '../store';


export const FetchModelsAction = () => {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const allModels: AllModels | undefined = await ModelsService.fetchAllModels();
        if (allModels) {
            const newAllModels = [];
            for (const model of Object.keys(allModels)) {
                const allSubmodels = allModels[model];
                for (const submodel of allSubmodels) {
                    const newModel: ModelWithType = {
                        type: model,
                        model: submodel
                    }
                    newAllModels.push(newModel);
                }
            }
            dispatch(setAllModels(newAllModels));
        }
    }
};
