import { auth } from "../firebaseConfigs";
import Memory, { MemoryResponse } from "../profile/models/Memory";

class MemoryService {
    static fetchMemories = async (): Promise<Memory[]> => {
        try {
            const token = await auth.currentUser?.getIdToken();

            const res = await fetch(`${process.env.REACT_APP_API_URL}/memory/fetch`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            const data: MemoryResponse = await res.json();
            console.log('Fetched memories:', data.results);
            return data.results;
        } catch (error) {
            console.error('Error fetching memories:', error);
        }
        return [];
    };

    static removeMemory = async (memory: Memory): Promise<boolean> => {
        try {
            const token = await auth.currentUser?.getIdToken();

            const res = await fetch(`${process.env.REACT_APP_API_URL}/memory/delete/${memory.id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (res.status !== 200) {
                console.error(`Failed to delete memory: ${res.status}`);
            }
            return true;
        } catch (error) {
            console.error('Error deleting memory:', error);
            return false;
        }
    };
}

export default MemoryService;
