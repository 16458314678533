import { ReactNode } from "react";

enum InterviewType {
    resume = 'resume',
    job = 'job',
    specified = 'specified',
}

export enum InterviewMode {
    voice = 'voice',
    text = 'text',
}

export const textForInterviewType = (type: InterviewType): ReactNode => {
    switch (type) {
        case InterviewType.resume:
            return <>I would like to do a general interview based on my <b>current resume</b></>
        case InterviewType.job:
            return <>I would like to do an interview for one of my <b>saved jobs</b></>
        case InterviewType.specified:
            return <>I would like to do an interview for a <b>specific type of job / role</b> I'm interested in</>
    }
}

export const textForInterviewMode = (type: InterviewMode): ReactNode => {
    switch (type) {
        case InterviewMode.text:
            return <>I would like to use text.</>
        case InterviewMode.voice:
            return <>I would like to use voice.</>
    }
}

export default InterviewType;