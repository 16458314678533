import CoverLetterType from "../enums/CoverLetterType";
import InterviewQuestionsType from "../enums/InterviewQuestionsType";
import JobMatchType from "../enums/JobMatchType";
import ResumeFixType from "../enums/ResumeType";
import TopLevelJobMatchType from "../enums/TopLevelJobMatchType";
import UpskillingType from "../enums/UpskillingType";
import ChatData from "./ChatData";
import Interview from "./Interview";
import Message, { MessageType } from "./Message";

interface Chat {
    created: string;
    updated: string;
    messages: Message[];
    msgTotal: number;
    sessionId: string;
    sessionTitle: string;
    pending?: boolean;
    streaming?: boolean;
    stoppedByUser?: boolean;
    error?: boolean;
    suggestions?: string[];
    scrollToIndex?: number;
    interview?: Interview;
    data?: ChatData;
    isInterviewTopLevel?: boolean;
    isInterviewQuestionsChat?: boolean;
    interviewQuestionsType?: InterviewQuestionsType;
    isResumeChat?: boolean;
    resumeFixType?: ResumeFixType;
    isCoverLetterChat?: boolean;
    coverLetterType?: CoverLetterType;
    coverLetterGenerated?: boolean;
    isTopLevelJobMatchChat?: boolean;
    isJobMatchChat?: boolean;
    jobMatchType?: JobMatchType;
    isUpskillChat?: boolean;
    upskillType?: UpskillingType;
}

export const newChat = (originalMessage: Message): Chat => {
    return {
        created: (new Date()).toISOString(),
        updated: (new Date()).toISOString(),
        messages: [
            originalMessage,
        ] as Message[],
        sessionId: '',
        sessionTitle: '...',
        pending: true,
        msgTotal: 1,
    }
};

function makeId(length: number) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}

export const newInterviewChat = (originalMessage: Message, title: string, interview: Interview): Chat => {
    return {
        created: (new Date()).toISOString(),
        updated: (new Date()).toISOString(),
        messages: [
            originalMessage,
        ] as Message[],
        sessionId: `start-interview-${makeId(12)}`,
        sessionTitle: title,
        pending: false,
        msgTotal: 1,
        interview: interview,
    }
};

export const newResumeChat = (originalMessages: Message[], title: string, fixType?: ResumeFixType): Chat => {
    return {
        created: (new Date()).toISOString(),
        updated: (new Date()).toISOString(),
        messages: originalMessages,
        sessionId: `start-resume-${makeId(12)}`,
        sessionTitle: title,
        pending: false,
        msgTotal: originalMessages.length,
        isResumeChat: true,
        resumeFixType: fixType,
    }
};

export const newTopLevelInterviewChat = (originalMessages: Message[], title: string): Chat => {
    return {
        created: (new Date()).toISOString(),
        updated: (new Date()).toISOString(),
        messages: originalMessages,
        sessionId: `start-interview-tl-${makeId(12)}`,
        sessionTitle: title,
        pending: false,
        msgTotal: originalMessages.length,
        isInterviewTopLevel: true,
    }
};

export const newInterviewQuestionsChat = (originalMessages: Message[], title: string, type?: InterviewQuestionsType): Chat => {
    return {
        created: (new Date()).toISOString(),
        updated: (new Date()).toISOString(),
        messages: originalMessages,
        sessionId: `start-interview-qs-${makeId(12)}`,
        sessionTitle: title,
        pending: false,
        msgTotal: originalMessages.length,
        isInterviewQuestionsChat: true,
        interviewQuestionsType: type,
    }
};

export const newCoverLetterChat = (originalMessages: Message[], title: string, type?: CoverLetterType): Chat => {
    return {
        created: (new Date()).toISOString(),
        updated: (new Date()).toISOString(),
        messages: originalMessages,
        sessionId: `start-cover-letter-${makeId(12)}`,
        sessionTitle: title,
        pending: false,
        msgTotal: originalMessages.length,
        isCoverLetterChat: true,
        coverLetterType: type,
    }
};

export const newJobMatchChat = (originalMessages: Message[], title: string, type?: JobMatchType): Chat => {
    return {
        created: (new Date()).toISOString(),
        updated: (new Date()).toISOString(),
        messages: originalMessages,
        sessionId: `start-jobs-${makeId(12)}`,
        sessionTitle: title,
        pending: false,
        msgTotal: 2,
        isJobMatchChat: true,
        jobMatchType: type,
    }
};

export const newTopLevelJobMatchChat = (originalMessages: Message[], title: string, type?: TopLevelJobMatchType): Chat => {
    return {
        created: (new Date()).toISOString(),
        updated: (new Date()).toISOString(),
        messages: originalMessages,
        sessionId: `start-jobs-${makeId(12)}`,
        sessionTitle: title,
        pending: false,
        msgTotal: 2,
        isTopLevelJobMatchChat: true,
    }
};


export const newUpskillingChat = (originalMessages: Message[], title: string, type?: UpskillingType): Chat => {
    return {
        created: (new Date()).toISOString(),
        updated: (new Date()).toISOString(),
        messages: originalMessages,
        sessionId: `start-jobs-${makeId(12)}`,
        sessionTitle: title,
        pending: false,
        msgTotal: 2,
        isUpskillChat: true,
        upskillType: type,
    }
};

export const appendNewStreamingResponse = (chat: Chat, pending?: boolean): Chat => {
    return {
        ...chat,
        streaming: true,
        pending: pending ?? chat.pending ?? false,
        messages: [
            ...chat.messages,
            {
                type: MessageType.received,
                content: '',
                createdAt: new Date(),
            }
        ] as Message[],
    }
};

export const appendFunctionToChat = (chat: Chat, message: Message): Chat => {
    const insertIndex = chat.messages.length - 1;
    if (insertIndex < 0) {
        return chat;
    }
    const newMessages = [...chat.messages];
    newMessages.splice(insertIndex, 0, message);
    return {
        ...chat,
        streaming: true,
        messages: newMessages,
    }
};

export const updateStreamingChatMessage = (chat: Chat, fragment: string): Chat => {
    const lastMessageIndex = chat.messages.length - 1;
    const lastMessage = chat.messages[lastMessageIndex];
    const updatedLastMessage = {
        ...lastMessage,
        content: lastMessage.content + fragment,
    };
    const updatedMessages = [...chat.messages];
    updatedMessages[lastMessageIndex] = updatedLastMessage;
    return {
        ...chat,
        pending: false,
        messages: updatedMessages,
    };
};

export const addMessageIndexToStreamingChat = (chat: Chat, index: number): Chat => {
    const lastMessageIndex = chat.messages.length - 1;
    const lastMessage = chat.messages[lastMessageIndex];
    const updatedLastMessage = {
        ...lastMessage,
        i: index,
    };
    const updatedMessages = [...chat.messages];
    updatedMessages[lastMessageIndex] = updatedLastMessage;
    return {
        ...chat,
        pending: false,
        messages: updatedMessages,
    };
};

export const addSessionInfo = (chat: Chat, json: any): Chat => {
    return {
        ...chat,
        created: json["created"],
        sessionId: json["session_id"],
        sessionTitle: json["session_title"],
        pending: false,
        streaming: false,
    }
};

export const getLastUserMessage = (chat: Chat): Message | undefined => {
    return chat.messages.filter(m => m.type === MessageType.sent).pop();
}

export const chatIsInterview = (chat: Chat): boolean => {
    return !!chat.interview && Object.keys(chat.interview).length > 0;
}

export default Chat;