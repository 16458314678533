import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { ChangeEvent, useCallback, useState } from 'react';
import AddResumeIcon from '../../assets/icons/add_resume_icon';
import PDFIcon from '../../assets/icons/pdf_icon';
import SmallXIcon from '../../assets/icons/small_x_icon';
import { useAppSelector } from '../../redux/hooks';
import ClickableOpacityDiv from '../../shared/components/ClickableOpacityDiv';
import { formatFileSize } from '../../shared/utils/StringUtils';
import { AppColors } from '../../theme/AppTheme';
import IResume from '../../shared/interfaces/IResume';

const StyledDiv = styled('div')((props: { droppingFile: boolean }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    border: `2px solid ${AppColors.grey.border}`,
    borderRadius: '12px',
    padding: '54px 0px',
    width: '100%',
    opacity: props.droppingFile ? 0.6 : 1,
}));


interface ResumeUploadBoxProps {
    file?: File;
    existingResume?: IResume;
    onFileChanged: (event: ChangeEvent<HTMLInputElement>) => void;
    onRemoveFile: () => void;
}

const ResumeUploadBox = (props: ResumeUploadBoxProps) => {
    const { file, existingResume, onFileChanged, onRemoveFile } = props;
    const [isDragActive, setIsDragActive] = useState(false);
    const isMobile = useAppSelector(store => store.appState.isMobile);

    const onBrowseFilesPressed = () => {
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = 'application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        fileInput.addEventListener('change', (event: unknown) => onFileChanged(event as ChangeEvent<HTMLInputElement>));
        fileInput.click();
    }

    const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsDragActive(true);
    };

    const onDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsDragActive(false);
    };


    const onDrop = useCallback((event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsDragActive(false);
        const files = event.dataTransfer.files;
        if (files.length) {
            const file = files[0];
            onFileChanged({ target: { files: [file] } } as unknown as ChangeEvent<HTMLInputElement>);
        }
    }, [onFileChanged]);

    if (!!file || !!existingResume) {
        return (
            <StyledDiv
                droppingFile={isDragActive}
                onDragOver={onDragOver}
                onDragLeave={onDragLeave}
                onDrop={onDrop}
            >
                <PDFIcon />
                <Typography variant='body1' style={{ color: AppColors.blue, marginTop: '12px', textAlign: 'center' }}>
                    {`${file?.name ?? existingResume?.fileName} [${formatFileSize(file?.size ?? existingResume!.fileSize)}]`}
                </Typography>
                <Typography variant='h5' style={{ fontSize: '14px', color: AppColors.grey.darkish, marginTop: '24px', textAlign: 'center' }}>
                    <ClickableOpacityDiv onClick={onRemoveFile}><SmallXIcon /> <span style={{ marginLeft: '4px' }}>Remove</span></ClickableOpacityDiv>
                </Typography>
            </StyledDiv>
        );
    }

    return (
        <StyledDiv
            droppingFile={isDragActive}
            onDragOver={onDragOver}
            onDragLeave={onDragLeave}
            onDrop={onDrop}
        >
            <AddResumeIcon />

            <Typography variant='h5' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: AppColors.grey.darkish, marginTop: '24px', textAlign: 'center' }}>
                <p>{isMobile ? 'Add your PDF resume.' : 'Drag and drop PDF resume here'}</p>
                <p style={{ display: 'flex' }} >{isMobile ? '' : 'or '}<ClickableOpacityDiv onClick={onBrowseFilesPressed} style={{ color: AppColors.link, marginLeft: '4px' }}>Browse Files.</ClickableOpacityDiv></p>
            </Typography>

        </StyledDiv>
    )
}

export default ResumeUploadBox