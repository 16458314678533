import styled from '@emotion/styled';
import { Check } from '@mui/icons-material';
import { AppBar, IconButton, Input, Toolbar, Typography, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import CloseIcon from '../assets/icons/close_icon';
import HamburgerIcon from '../assets/icons/hamburger_icon';
import InterviewIcon from '../assets/icons/interview_icon';
import RewriteIcon from '../assets/icons/rewrite_icon';
import SaveIconSelected from '../assets/icons/save_icon_selected';
import SaveIconUnselected from '../assets/icons/save_icon_unselected';
import InterviewContentPage from '../chat/pages/InterviewContentPage';
import JobDetailsContentPage from '../chat/pages/JobDetailsContentPage';
import MainChatPage from '../chat/pages/MainChatPage';
import ResourceDetailsContentPage from '../chat/pages/ResourceDetailsContentPage';
import ResumeContentPage from '../chat/pages/ResumeContentPage';
import SavedResourceListMobileModal from '../mainTabs/savedResources/SavedResourceListMobileModal';
import { SavedResumeType } from '../mainTabs/savedResumes/model/SavedResume';
import { ProfileTabForIndex } from '../profile/ProfileNavigationPage';
import { useAppSelector } from '../redux/hooks';
import { setDrawerIsOpen, updateChatSession } from '../redux/reducers/appReducer';
import { addSavedInterview, removeSavedInterview } from '../redux/reducers/interviewReducer';
import { addResourceToList, addSavedResource, clearFocusedResource, removeSavedResource, toggleResources } from '../redux/reducers/resourceReducer';
import { addSavedJob, removeSavedJob } from '../redux/reducers/savedJobsReducer';
import { addSavedResume, removeSavedResume } from '../redux/reducers/savedResumeReducer';
import { addSavedChat, removeSavedChat, updateSavedChat } from '../redux/reducers/userReducer';
import ChatService from '../services/chatService';
import InterviewService from '../services/interviewService';
import JobsService from '../services/jobsService';
import ResourcesService from '../services/resourcesService';
import ResumeService from '../services/resumeService';
import SavedChatsService from '../services/savedChatsService';
import NotificationBar, { NotificationType } from '../shared/components/NotificationBar';
import Chat from '../shared/models/Chat';
import SavedChat from '../shared/models/SavedChat';
import zIndicies from '../shared/utils/zIndexConstants';
import { AppColors } from '../theme/AppTheme';
import AppDrawer from './components/AppDrawer';

const kAppBarHeight = '60px';

const DrawerBackground = styled('div')(({ theme }) => ({
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: zIndicies.mobileNavDrawerBg,
    opacity: 0.5,
    transition: 'background 0.3s ease-in-out',
}));

export default function MobileNavigation() {
    const drawerIsOpen = useAppSelector(store => store.appState.drawerIsOpen);
    const activeChat = useAppSelector(store => store.appState.activeChat);
    const selectedJob = useAppSelector(store => store.savedJobsState.selectedJob);
    const selectedResource = useAppSelector(store => store.resourceState.selectedSavedResource);
    const selectedInterview = useAppSelector(store => store.interviewState.selectedInterview);
    const selectedProfileTab = useAppSelector(store => store.appState.selectedProfileTab);
    const fetchingChat = useAppSelector(store => store.appState.fetchingChat);
    const savedChat = useAppSelector(store => store.userState.currentUser?.savedChats.find(c => c.sessionId === activeChat?.sessionId));
    const [saving, setSaving] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [isEditingTitle, setIsEditingTitle] = useState(false);
    const [title, setTitle] = useState(activeChat?.sessionTitle ?? '');
    const theme = useTheme();
    const dispatch = useDispatch();

    const savedJobs = useAppSelector(store => store.savedJobsState.jobsArray ?? []);
    const jobToggle = useAppSelector(store => store.savedJobsState.savedJobsToggle);
    const [isBookmarked, setIsBookmarked] = useState(false);

    const savedResources = useAppSelector(store => store.resourceState.savedResources ?? {});
    const savedResourcesArray = Object.values(savedResources).flat();
    const resourceLists = useAppSelector(store => store.resourceState.resourceList);
    const [listModalOpen, setListModalOpen] = useState(false);
    const [resourceNotificationOpen, setResourceNotificationOpen] = useState(false);
    const [shouldCloseNotification, setShouldCloseNotification] = useState(false);

    const savedInterviews = useAppSelector(state => state.interviewState.interviewsArray);
    const interviewToggle = useAppSelector(state => state.interviewState.interviewToggle);
    const [interviewNotificationOpen, setInterviewNotificationOpen] = useState(false);

    const selectedSavedResume = useAppSelector(store => store.savedResumesState.selectedResume);
    const savedResumes = useAppSelector(store => store.savedResumesState.resumesArray);
    const savedResumeToggle = useAppSelector(store => store.savedResumesState.savedResumeToggle);
    const [resumeNotificationOpen, setResumeNotificationOpen] = useState(false);

    const onBookmarkToggled = () => {
        if (selectedJob) {
            onJobBookmarkToggled();
        } else if (selectedResource) {
            onResourceBookmarkToggled();
        } else if (selectedInterview) {
            onInterviewBookmarkToggled();
        } else if (selectedSavedResume) {
            onResumeBookmarkToggled();
        }
    }

    const onJobBookmarkToggled = async () => {
        if (!selectedJob) return;
        if (!isBookmarked) {
            setIsBookmarked(true);
            const savedJob = await JobsService.saveJob(selectedJob);
            dispatch(addSavedJob(selectedJob));
            if (!savedJob) {
                setIsBookmarked(false);
            }
        } else {
            await JobsService.unsaveJob(selectedJob)
            dispatch(removeSavedJob(selectedJob));
            setIsBookmarked(false);
        }
    }

    const onResourceBookmarkToggled = async () => {
        if (!selectedResource) return;
        if (!isBookmarked) {
            setIsBookmarked(true);
            const savedResource = await ResourcesService.saveResource(selectedResource);
            if (savedResource) {
                dispatch(addSavedResource(savedResource));
                const favouritesList = resourceLists.find(list => list.name === 'Likes');
                if (favouritesList) {
                    const success = await ResourcesService.addResourceToList(favouritesList.id, savedResource.url);
                    if (success) {
                        dispatch(addResourceToList({ listId: favouritesList.id, resource: savedResource }));
                        setResourceNotificationOpen(true);
                    }
                } else {
                    console.error("Couldn't find likes list");
                }
            }
            if (!savedResource) {
                setIsBookmarked(false);
            }
        } else {
            setListModalOpen(true);
        }
    }

    const onInterviewBookmarkToggled = async () => {
        if (!selectedInterview) return;
        if (isBookmarked) {
            await InterviewService.removeSavedInterview(selectedInterview);
            dispatch(removeSavedInterview(selectedInterview));
        } else {
            await InterviewService.saveInterview(selectedInterview);
            dispatch(addSavedInterview(selectedInterview));
            setInterviewNotificationOpen(true);
        }
        setIsBookmarked(!isSaved);
    }

    const onResumeBookmarkToggled = async () => {
        if (!selectedSavedResume) return;
        if (isBookmarked) {
            await ResumeService.unsaveResume(selectedSavedResume);
            dispatch(removeSavedResume(selectedSavedResume));
        } else {
            await ResumeService.saveResume(selectedSavedResume.title, selectedSavedResume.content, selectedSavedResume.type ?? SavedResumeType.resume);
            dispatch(addSavedResume(selectedSavedResume));
            setResumeNotificationOpen(true);
        }
        setIsBookmarked(!isSaved);
    }

    useEffect(() => {
        if (selectedJob) {
            const isJobSaved = savedJobs.some(j => (!!j.id && j.id === selectedJob?.jobId) || (j.url === selectedJob?.url));
            setIsBookmarked(isJobSaved);
        } else if (selectedResource) {
            const isSaved = savedResourcesArray.some(j => j.url === selectedResource.url);
            setIsBookmarked(isSaved);
        } else if (selectedInterview) {
            const isSaved = savedInterviews.some(i => i.id === selectedInterview.id);
            setIsBookmarked(isSaved);
        } else if (selectedSavedResume) {
            const isSaved = savedResumes.some(i => i.id === selectedSavedResume.id);
            setIsBookmarked(isSaved);
        }
    }, [selectedJob, selectedResource, savedResourcesArray, savedJobs, jobToggle, interviewToggle, selectedInterview, savedInterviews, savedResumes, savedResumes, savedResumeToggle]);

    const isSaved = !!savedChat && savedChat.savedIndex < 0;

    useEffect(() => {
        setTitle(activeChat?.sessionTitle ?? '');
        setIsEditingTitle(false);
    }, [activeChat]);

    const onChatSave = async (chat: Chat) => {

        const savedChatData: Partial<SavedChat> = {
            title: chat.sessionTitle ?? '',
            sessionId: chat.sessionId,
            chatCreatedAt: chat.created,
            savedIndex: -1,
        }
        setSaving(true);
        const savedChat = await SavedChatsService.saveChat(savedChatData);
        if (savedChat) {
            dispatch(addSavedChat(savedChat))
            if (snackbarOpen)
                setSnackbarOpen(false);
            setSnackbarOpen(true);
        }
        setSaving(false);
    }

    const onUnsaveChat = async () => {
        if (!savedChat) return;
        dispatch(removeSavedChat(savedChat));
        const success = await SavedChatsService.unsaveChat(savedChat);
        if (!success)
            dispatch(addSavedChat(savedChat));
        else {
            if (snackbarOpen)
                setSnackbarOpen(false);
            setSnackbarOpen(true);
        }
    }

    const actionButtonClicked = () => {
        if (!activeChat || saving) return;
        if (isSaved) {
            onUnsaveChat();
        } else {
            onChatSave(activeChat);
        }
    }

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const action = (
        <>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon />
            </IconButton>
        </>
    );

    const onTitleChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(event.target.value);
    }

    const onTitleAction = async () => {
        if (isEditingTitle) {
            if (!activeChat) return;
            setIsEditingTitle(false);
            const updatedSession = await ChatService.updateTitle(activeChat.sessionId, title);
            if (!updatedSession) {
                setTitle(activeChat.sessionTitle ?? '');
            } else {
                if (isSaved) {
                    dispatch(updateSavedChat({ ...savedChat!, title }));
                    await SavedChatsService.updateTitle(savedChat, title);
                }
                dispatch(updateChatSession(updatedSession))
            }
        } else {
            setIsEditingTitle(true);
        }
    }

    const onListModalClosed = async (shouldDelete: boolean) => {
        setListModalOpen(false);
        setShouldCloseNotification(true);
        if (shouldDelete && selectedResource) {
            dispatch(clearFocusedResource());
            await ResourcesService.unsaveResource(selectedResource)
            dispatch(removeSavedResource(selectedResource));
            setIsBookmarked(false);
            dispatch(toggleResources());
        }
    }

    const showChatInfo = !!activeChat && !selectedJob && !selectedResource && !selectedInterview && selectedProfileTab === undefined && !fetchingChat && !selectedSavedResume;
    return (
        <Box sx={{ position: 'relative', display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
            <DrawerBackground onClick={() => dispatch(setDrawerIsOpen(false))} style={{
                backgroundColor: drawerIsOpen ? AppColors.grey.dark : undefined,
                pointerEvents: drawerIsOpen ? 'auto' : 'none',
            }} />
            <AppDrawer isMobile={true} />
            <AppBar position="fixed" elevation={0} sx={{ zIndex: zIndicies.mobileAppBar, flexDirection: 'row', height: kAppBarHeight, borderBottom: `1px solid ${AppColors.grey.light}`, width: '100%' }}>
                <Toolbar disableGutters={true} sx={{ paddingLeft: '15px', paddingRight: '15px', width: 'calc(100% - 30px)', display: 'flex', flexDirection: 'row', zIndex: 2, justifyContent: 'space-between', }}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => dispatch(setDrawerIsOpen(true))}
                        edge="start"
                        sx={{
                            padding: '21px',
                        }}
                    >
                        <HamburgerIcon />
                    </IconButton>
                    <Typography variant="h4" style={{ whiteSpace: 'nowrap', textAlign: 'left', lineHeight: '14px', width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '12px' }}>
                        {showChatInfo ? (isEditingTitle
                            ? <Input
                                autoFocus
                                autoComplete="off"
                                id={'session-title-input'}
                                value={title}
                                onChange={onTitleChanged}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        onTitleAction();
                                    }
                                }}
                                placeholder='Enter chat title'
                                style={{ ...theme.typography.h4, fontSize: '12px', width: '100%' }}
                            />
                            : title) : null}
                    </Typography>
                    {showChatInfo &&
                        <IconButton
                            onClick={onTitleAction}
                            sx={{
                                padding: '12px 12px',
                                opacity: 1,
                                display: 'flex', alignItems: 'center', justifyContent: 'center',
                                maxHeight: '60px',
                                paddingTop: '14px'
                            }}
                        >
                            <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                {isEditingTitle ? <Check htmlColor={AppColors.black} /> : <RewriteIcon color={AppColors.black} />}
                            </span>
                        </IconButton>}
                    <IconButton
                        onClick={actionButtonClicked}
                        sx={{
                            padding: '12px',
                            opacity: showChatInfo ? 1 : 0,
                            display: 'flex', alignItems: 'center', justifyContent: 'center',
                            maxHeight: '60px',
                            paddingTop: '14px',
                            paddingLeft: '12px',
                        }}
                    >
                        <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: '22px' }}>{!!activeChat ? (isSaved || saving) ? <SaveIconSelected /> : <SaveIconUnselected /> : <HamburgerIcon />}</span>
                    </IconButton>
                    {(selectedJob || selectedResource || selectedInterview) && <IconButton
                        onClick={onBookmarkToggled}
                        sx={{
                            padding: '12px',
                            display: 'flex', alignItems: 'center', justifyContent: 'center',
                            maxHeight: '60px',
                            paddingTop: '14px',
                            paddingLeft: '12px',
                        }}
                    >
                        {isBookmarked ? <SaveIconSelected /> : <SaveIconUnselected />}
                    </IconButton>}
                </Toolbar>
            </AppBar>
            <div style={{ overflowX: 'hidden', width: '100vw', height: 'calc(100% - 60px)', marginTop: '60px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                {selectedProfileTab !== undefined ? ProfileTabForIndex(selectedProfileTab) : selectedJob
                    ? <JobDetailsContentPage animated={false} job={selectedJob} />
                    : (selectedResource ? <ResourceDetailsContentPage animated={false} resource={selectedResource} /> :
                        selectedInterview ? <InterviewContentPage animated={false} interview={selectedInterview} />
                            : selectedSavedResume ? <ResumeContentPage animated={false} type={selectedSavedResume.type ?? SavedResumeType.resume} savedResume={selectedSavedResume} messageIndex={0} />
                                : <MainChatPage />)}
            </div>
            {snackbarOpen &&
                <NotificationBar
                    type={NotificationType.chat}
                    marginTop={59}
                    onClose={() => setSnackbarOpen(false)}
                    shouldClose={false}
                    message={isSaved ? 'Added to saved chats' : 'Removed from saved chats'}
                />}
            {resourceNotificationOpen &&
                <NotificationBar
                    type={NotificationType.resource}
                    marginTop={59}
                    onClose={() => {
                        setShouldCloseNotification(false);
                        setResourceNotificationOpen(false);
                    }}
                    shouldClose={shouldCloseNotification}
                    onAction={() => {
                        setListModalOpen(true);
                    }} />
            }
            {interviewNotificationOpen &&
                <NotificationBar
                    type={NotificationType.message}
                    marginTop={59}
                    onClose={() => setInterviewNotificationOpen(false)}
                    shouldClose={false}
                    message={'Added to Saved Interviews'}
                    icon={<InterviewIcon color={AppColors.white} />}
                />
            }
            {resumeNotificationOpen &&
                <NotificationBar
                    type={NotificationType.message}
                    marginTop={59}
                    onClose={() => setResumeNotificationOpen(false)}
                    shouldClose={false}
                    message={'Resume added to your saved resumes'}
                    icon={<InterviewIcon color={AppColors.white} />}
                />
            }
            {listModalOpen && selectedResource &&
                <SavedResourceListMobileModal
                    backgroundClicked={onListModalClosed}
                    savedResource={selectedResource}
                />
            }
        </Box >
    );
}