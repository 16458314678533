import { auth } from "../firebaseConfigs";

export interface AllModels {
    [providerId: string]: string[];
}

export interface ModelWithType {
    type: string;
    model: string;
}

class ModelsService {
    static fetchAllModels = async (): Promise<AllModels | undefined> => {
        try {
            // Ensure the user is authenticated
            const currentUser = auth.currentUser;
            if (!currentUser) {
                console.error('No authenticated user found.');
                return;
            }

            // Get the ID token for authentication
            const token = await currentUser.getIdToken();

            // Fetch the models from the backend
            const res = await fetch(`${process.env.REACT_APP_API_URL}/data/models`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            // Check if the response is successful
            if (!res.ok) {
                console.error('Error fetching models:', res.statusText);
                return;
            }

            // Parse the JSON response
            const data: AllModels = await res.json();
            return data;
        } catch (error) {
            console.error('Error fetching models:', error);
            return;
        }
    };
}

export default ModelsService;