import styled from "@emotion/styled";
import { InterviewQuestionsSelectedAction } from "../../redux/actions/InterviewActions";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import SelectionPill from "../../shared/components/SelectionPill";
import PrimaryButton from "../../shared/components/PrimaryButton";
import { setVoiceInterviewActive } from "../../redux/reducers/appReducer";
import { InterviewState } from "../../shared/models/Interview";

const StyledRow = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    maxWidth: '100%',
    gap: '15px',
}));


const StartVoiceInterviewButton = () => {
    const dispatch = useAppDispatch();
    const activeChat = useAppSelector(store => store.appState.activeChat);
    if (activeChat?.pending || activeChat?.streaming || activeChat?.interview?.state === InterviewState.completed) return null;

    return (
        <StyledRow>
            <PrimaryButton onClick={() => dispatch(setVoiceInterviewActive(true))} title={(activeChat?.interview?.answers?.length ?? 0) > 0 ? "Continue Interview" : "Start Interview"} />
        </StyledRow>
    )
}

export default StartVoiceInterviewButton;